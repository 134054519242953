import React from "react";

const LifeAtFubeusContent = () => {
  return (
    <div className="about-area-wrapper sm-top">
      <div className="container life-at-fb-intro">
        <h2>ENGAGE, INNOVATE, EMPOWERMENT, REWARD</h2>
        <span>
          Work, entertainment and fun co-exist at Fubeus. Our technologists take
          time off work to engage in various activities. Working with FUBEUS is
          becoming associated with the best minds in the industry wherein you
          get the right platform to explore beyond possibilities. Our success
          revolves around innovation, divergent thinking, dedication. We strive
          to provide an environment where you can develop your potential. By
          providing regular training opportunities, on-the-job development,
          coaching and more, we enable employees to realize their full
          potential.
          <br />
          <br />
          <br />
        </span>
        <h3>CELEBRATIONS AT FUBEUS</h3>
        <figure className="blog-post-thumb">
          <img
            src={require("../../assets/img/life-at-fubeus/01.jpg")}
            width="1438"
          />
        </figure>
      </div>
    </div>
  );
};

export default LifeAtFubeusContent;
