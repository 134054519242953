import React,{Fragment} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import ContentType05 from '../components/Competencies/5g/contentType05';

const PageServiceDetails = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/about-us-header.jpg')}
                title="Virtual and ORAN Services"
                content=""
            />
            <ContentType05/>
            <Footer/>
        </Fragment>
    );
};

export default PageServiceDetails;