import React, { useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Thumbnail from "../UI/Thumbnail";
import Content from "../UI/Content";
import embeddedSystemsData from "../../data/ServicePages/qualityAnalysis.json";
import DetailsType01 from "../Common/DetailsType01";
import DetailsType02 from "../Common/DetailsType02";

const QualityAnalysis = () => {
  const [data] = useState(embeddedSystemsData);

  return (
    <div className="about-area-wrapper sm-top">
      <div className="container">
        <span align="justify">
          Fubeus wide range of experience in Silicon to Embedded and application
          software validation and testing proved vital for many of our
          customer’s success stories across platforms and domains.
          <br />
          <br />
        </span>
        <h2 className="text-center" style={{ color: "#5C3C9C" }}>
          QA CAPABILITIES
        </h2>
        <p align="justify">
          Fubeus QA Group is one of the largest and equipped with highly skilled
          professionals with high quality delivery track record. Our goal is to
          ensure that the software meets all requirements and user expectations
          in a timely way by providing effective methodologies, techniques, and
          tools that provide assurance for the quality and integrity of the
          product through our unique Test management and Bug tracking tools.
        </p>
        <br />
        <br />
        <div
          className="d-flex"
          style={{ alignItem: "center", justifyContent: "center" }}
        >
          <img
            classes="about-thumb"
            src={require("../../assets/img/service/qualityAnalysis/QA1.jpg")}
            style={{ height: "420px", width: "760px" }}
          ></img>
        </div>
        <br />
        <p align="justify">
          Our QA group has been a pioneered many industries’ first methodology
          and investing in continues improvement in methodologies for various
          domains and constantly in adapting to trending technologies and
          methodologies in the market. Fubeus is well acquainted in both{" "}
          <b>
            {" "}
            Manual andAutomation Testing having expertise in performing
            Functional Testing, Performance Testing, Regression Testing,
            Security Testing{" "}
          </b>{" "}
          and our wide range of experience in Embedded and Application software
          testing proved vital for many of our customer’s success stories across
          different domains.
        </p>
        <h4 style={{ color: "#5C3C9C" }}>
          <b>APPLICATION QA</b>
        </h4>
        <p align="justify">
          At Fubeus, we own and deliver more than just software development. We
          provide a full range of solutions such as software maintenance,
          customization, deployment, and consulting. Our team has extensive
          experience in application testing for various domains{" "}
          <b>
            like E-commerce, IOT apps, Mobile apps (Android, iOS) Multi-channel
            Interfaces, ERP and Solution Software.
          </b>
        </p>
        <DetailsType02
          img={data[0].thumb}
          title={data[0].title}
          description={data[0].text}
        />
        <br />
        <h4 className="text-center" style={{ color: "#5C3C9C" }}>
          <b>APPLICATION QA TOOLS EXPERIENCE AND EXPOSURE</b>
        </h4>
        <div
          className="d-flex"
          style={{ alignItem: "center", justifyContent: "center" }}
        >
          <Thumbnail
            classes="about-thumb"
            imgSrc={require("../../assets/img/service/qualityAnalysis/applicationQa.png")}
          />
        </div>

        <h4 style={{ color: "#5C3C9C" }}>
          <b>EMBEDDED QA</b>
        </h4>
        <p align="justify">
          By working with some of the Fortune 100 organizations especially in
          Semiconductor domains Fubeus gained expertise in validating a wide
          range of embedded domains like Processor, Networking, Wireless,
          Storage, Multimedia, CAN etc. Fubeus Embedded QA was instrumental in
          some of the high stake Validation projects and delivered best possible
          results to our customers by providing our services in validating
          different embedded devices/applications like System Drivers, Display
          Drivers, Graphics devices, Sensor devices, Platform validation, BIOS
          validation, Storage devices, Benchmarking tools, Power management
          tools
        </p>
        <DetailsType02
          img={data[1].thumb}
          title={data[1].title}
          description={data[1].text}
        />
        <p align="justify">
          With proven proficiency in designing Automation solutions for Embedded
          software and automating the test suite we have a familiarity on
          different OS and it’s flavors of Windows versions and Linux
          (Ubuntu,RHEL) along with automating mobile applications using Appium
          and Android based UIAutomator using embedded CI/CD process and design
          using Git, Jenkins, Docker etc
        </p>
        <h4 className="text-center" style={{ color: "#5C3C9C" }}>
          <b>EMBEDDED QA TOOLS</b>
        </h4>
        <div
          className="d-flex"
          style={{ alignItem: "center", justifyContent: "center" }}
        >
          <Thumbnail
            classes="about-thumb"
            imgSrc={require("../../assets/img/service/qualityAnalysis/embeddedQa.png")}
          />
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default QualityAnalysis;
