import React from "react";
import SectionTitle from "../../UI/SectionTitle";

import teamBg from "./../../../assets/img/about-us-group.jpg";
import teamData from "../../../data/Team/home-two";
import TeamMember from "./member";
import Thumbnail from "../../UI/Thumbnail";
import aboutData from "../../../data/About/home-one.json";

const Team = () => {
  return (
    <div>
      <div className="about-area-wrapper sm-top">
        <div className="container">
          <span className="about-us-intro" align="justify">
            Fubeus derived from three words “Future Belongs to Us”. Our constant
            intriguing of ourselves to develop something new out of the box
            product from India with enormous talented engineers who never
            received an opportunity, recognition and limelight for their hard
            work and hence providing them with an opportunity to build a
            revolutionizing product which will make them feel privileged and
            preparing them to touch every walk of life just like ordinary
            people.
          </span>
          <br />
          <br />
        </div>
      </div>
      <div className="team-area bg-brand">
        <div className="row no-gutters align-items-center w-100">
          <div className="col-xl-5">
            <div className="team-area-left text-center text-md-left">
              <SectionTitle
                variant="light"
                title=""
                heading=""
                text={aboutData.text}
              />
            </div>
          </div>

          <div
            className={window.innerWidth <= 450 ? "col-xl-7 ms-2" : "col-xl-7"}
          >
            <Thumbnail
              classes="about-thumb"
              imgSrc={require("./../../../assets/img/about-us-group.jpg")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
