import React, {useState} from 'react';
import {Link} from "react-router-dom";
import parse from "html-react-parser";
import Thumbnail from "../UI/Thumbnail";
import Content from "../UI/Content";
import HealthData from '../../data/Competencies/health.json'
import IntegrationCards from '../../data/Competencies/telecommunicationIntegration.json';
import TeleCommunicationData from '../../data/Competencies/telecommunication.json';
import TestingCards from '../../data/Competencies/telecommunicationTesting.json'
import DetailsType01 from '../Common/DetailsType01';
import DetailsType02 from '../Common/DetailsType02';
import CardItem from "../Common/CardItem";

const TeleCommunication = () => {

    const [data] = useState(TeleCommunicationData);

    return (
        <div className="about-area-wrapper sm-top">
            <div className="container"> 
                <h3 style={{color: '#5C3C9C'}}><b>TELECOM SOFTWARE DEVELOPMENT</b></h3>
                <p>Fubeus makes your services stand out by powering your telecom ecosystem with advanced technologies. We have  accomplished a telecom development company that builds tailored software solutions for internet, landline and media service providers, broadcasting companies, mobile and satellite network operators, to help them maintain their business and networks, expand service coverage, and enhance customer engagement.</p>
                <div className='d-flex' style={{alignItem : "center" , justifyContent : "center"}}>
                            <img classes="about-thumb" src={require('../../assets/img/competencies/telecom/01.jpg')} style
                            ={{height: '296px', width :"420px"}} ></img>
                </div> <br/><br/>
                <h3 className='text-center' style={{color: '#5C3C9C'}}><b>TELECOM BUSINESS SUPPORT SYSTEMS</b></h3>
                <DetailsType02 img ={data[1].img} title = {data[1].title} description ={data[1].text}/>
                <DetailsType01 img ={data[0].img} title = {data[0].title} description ={data[0].text}/>
                <DetailsType02 img ={data[2].img} title = {data[2].title} description ={data[2].text}/>
                <DetailsType01 img ={data[3].img} title = {data[3].title} description ={data[3].text}/>
                <h3 className='text-center' style={{color: '#5C3C9C'}}><b>ENTERPRISE APPLICATION INTEGRATION</b></h3>
                <DetailsType02 img ={data[4].img} title = {data[4].title} description ={data[4].text}/><br/>
                <div className="row mtn-35">
                    {
                        IntegrationCards.map(item =>(
                            <CardItem key={item.id} id={item.id} title={item.title} excerpt={item.excerpt} thumb={item.thumb} height = {item.height} width = {item.width} style = {{height:'370px',backgroundColor :'#d2cce4'}} readmore= "false"/>
                        ))
                    }
                </div><br/><br/>
                <h3 className='text-center' style={{color: '#5C3C9C'}}><b>OUR TESTING CAPABILITIES</b></h3><br/>
                <div className="row mtn-35">
                    {
                        TestingCards.map(item =>(
                            <CardItem key={item.id} id={item.id} title={item.title} excerpt={item.excerpt} thumb={item.thumb} height = {item.height} width = {item.width} cols= 'col-md-6 col-lg-6'/>
                        ))
                    }
                </div><br/><br/>
            </div>
        </div>
    );
};

export default TeleCommunication;