import React, { Fragment } from "react";

import Header from "../components/Header";
import Slider from "../components/Slider/home-one";
import Services from "../components/Services";
import Competencies from "../components/Competencies";
import BrandLogo from "../components/BrandLogo";
import Footer from "../components/Footer";
import Map from "../components/Map";

const HomeOne = () => {
  return (
    <Fragment>
      <Header />
      <Slider />
      <Services classes="sm-top-wt" />
      <Competencies classes="sm-top-wt" />
      <BrandLogo />
      <Map />
      <Footer />
    </Fragment>
  );
};

export default HomeOne;
