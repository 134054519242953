import React, {useState} from 'react';
import Thumbnail from "../../UI/Thumbnail";
import modemRanCard from '../../../data/Competencies/5g/modemRan.json';
import CardItem from "../../Common/CardItem";

const ContentType04 = () => {

    const [data] = useState(modemRanCard);

    return (
        <div className="about-area-wrapper sm-top">
            <div className="container">
                <span>Fubeus 5G Lab-as-a-Service drives innovation and collaboration, propelling the future of wireless technology. Our services offers a comprehensive range of lab services to accelerate your 5G initiatives and ensure fast and successful deployment.</span><br/>
                <span>From RAN component testing and E2E solution validation to private network POCs creation and user device testing, our 5G Labs covers all aspects of the 5G ecosystem. Our expert team, well-versed in 3GPP and ORAN standards, ensures compliance and interoperability throughout the process.</span>
                <span>Partner with Fubeus to access cutting-edge technology, industry expertise, and a collaborative environment for 5G testing. Together, let's unleash the full potential of 5G and revolutionize the way we connect and communicate.</span>
                <br/>
                <span>Our end-to-end 5G lab setup services include:</span>
                <ul style={{margin:'30px'}}>
                    <li style={{listStyle: 'disc'}}>Setting up 4G / 5G labs with open-source components for Core and RAN</li>
                    <li style={{listStyle: 'disc'}}>Prime integrator responsible for porting of RAN stacks on open-source hardware</li>
                    <li style={{listStyle: 'disc'}}>End-to-end integration between RAN, Core, and IMS systems</li>
                </ul><br/>
            </div>
        </div>
    );
};

export default ContentType04;