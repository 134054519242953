import React, { useState } from "react";
import FormInput from "../../components/UI/Input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const From = () => {
  const [fileData, setFileData] = useState({});
  const onChangeHandler = (event) => {
    setFileData(event.target.files[0]);
  };
  const sendEmail = async (event) => {
    event.preventDefault();
    const name = event.target.name.value;
    const email = event.target.email_address.value;
    const phone_no= event.target.phone_no.value;
    const position = event.target.position.value;
    const msg = event.target.con_message.value;
    let reader;
    let fileName;
    reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onloadend = async () => {
      fileName = fileData.name;
      const emailBody = `<h2>Team,</h2> <h2>We have new career enquiry from Fubeus website. Please contact and verify. Below are information, </h2> <h2> Candidate Name :  ${name} </h2>
                                <h2>Email ID : ${email} </h2>
                                <h2>Phone No : ${phone_no} </h2>
                                <h2>Position : ${position} </h2>
                                <h2>Message : ${msg} </h2>
                                <h2>Thanks,<br/> Fubeus support </h2>`;
// "https://node-smtp-server.onrender.com/
      await axios
        .post("http://localhost:5000/sendmail", {
          to: "lakshmanan.r@fubeus.com",
          subject: "Career enquiry from website",
          msg: emailBody,
          attachments: [
            {
              filename: fileName,
              path: reader.result,
              contentType: fileData.type
            },
          ],
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(
              "Thanks for writing us. Our Team will contact you shortly!",
              {
                position: "top-center",
              }
            );
          }
        })
        .catch((err) => {
          toast.error("Something went worng. Please try again. !", {
            position: "top-center",
          });
        });
      // window.Email.send({
      //   Host: "smtp.elasticemail.com",
      //   Username: "no-reply@fubeus.com",
      //   Password: "Elloura@Fubeus2024",//"B557C7C54E958B09A80650AE50BB59BD4041",
      //   To: "careers@fubeus.com",
      //   From: "no-reply@fubeus.com",
      //   Subject: "Career enquiry from website",
      //   Body: emailBody,
      //   Attachments: [
      //     {
      //       name: fileName,
      //       data: dataUri,
      //     },
      //   ],
      // }).then(function (message) {
      //   if (message === "OK") {
      //     toast.success(
      //       "Thanks for writing us. Our Team will contact you shortly!",
      //       {
      //         position: "top-center",
      //       }
      //     );
      //   } else {
      //     toast.error("Something went worng. Please try again. !", {
      //       position: "top-center",
      //     });
      //   }
      //   document.getElementById("contact-form").reset();
      // });
    };
  };

  return (
    <div className="contact-form-wrap">
      <form id="contact-form" onSubmit={sendEmail}>
        <div className="row">
          <div className="col-md-6">
            <FormInput
              tag={"input"}
              type={"text"}
              name={"name"}
              placeholder={"Name *"}
            />
          </div>

          <div className="col-md-6">
            <div className="single-input-item">
              <input
                type={"file"}
                id="myFile"
                name="filename"
                placeholder={"Choose file*"}
                onChange={onChangeHandler}
                required
              ></input>
            </div>
          </div>

          <div className="col-md-6">
            <FormInput
              tag={"input"}
              type={"email"}
              name={"email_address"}
              placeholder={"Email address *@"}
            />
          </div>

          <div className="col-md-6">
            <FormInput
              tag={"input"}
              type={"text"}
              name={"phone_no"}
              placeholder={"Phone No *"}
            />
          </div>
          <div className="col-md-6">
            <FormInput
              tag={"input"}
              type={"text"}
              name={"position"}
              placeholder={"Position *"}
            />
          </div>
          <div className="col-12">
            <FormInput
              tag={"textarea"}
              name={"con_message"}
              placeholder={"Write Your Message"}
            />

            <FormInput tag={"button"} classes={"btn-outline"} />

            <div className="form-message" />
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default From;
