import React from "react";

const FormInput = ({ tag, type, name, placeholder, classes, pattern }) => {
  console.log("==pattern", pattern);
  return (
    <div className="single-input-item">
      <label>
        {(() => {
          if (tag === "input" && type === "email") {
            return (
              <input
                type={type}
                name={name}
                pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                placeholder={placeholder}
                className={classes}
                required
              />
            );
          } else if (tag === "input") {
            return (
              <input
                type={type}
                name={name}
                placeholder={placeholder}
                className={classes}
                required
              />
            );
          } else if (tag === "textarea") {
            return (
              <textarea
                name={name}
                cols="30"
                rows="7"
                placeholder={placeholder}
                className={classes}
              />
            );
          } else if (tag === "button") {
            return (
              <button className={`btn-outline ${classes}`}>Send Message</button>
            );
          } else if (tag === "file") {
            return (
              <input
                type={type}
                id="myFile"
                name={name}
                placeholder={placeholder}
                className={classes}
              ></input>
            );
          }
        })()}
      </label>
    </div>
  );
};

export default FormInput;
