import React from 'react';


function Map() {
    return (
        // <div class="google-map"> 
        //     <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3887.4248592430263!2d77.6528679!3d13.0085947!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae112dc26c90c1%3A0xc06cf8329d7920b3!2sFubeus%20Technology%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1687511886385!5m2!1sen!2sin" width= {window.innerWidth <= "450"? "325" :"570"} height="400" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        //     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2931.7756345813177!2d-71.45744172493563!3d42.70847131302041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3bbd7f84765e5%3A0xa5775306fdf574a0!2sFubeus!5e0!3m2!1sen!2sin!4v1687516490239!5m2!1sen!2sin" width={window.innerWidth <= "450"? "325" :"600"} height="400" style= {{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

        //     {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3887.4248592430263!2d77.6528679!3d13.0085947!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae112dc26c90c1%3A0xc06cf8329d7920b3!2sFubeus%20Technology%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1687511886385!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        //     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2931.7756345813177!2d-71.45744172493563!3d42.70847131302041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3bbd7f84765e5%3A0xa5775306fdf574a0!2sFubeus!5e0!3m2!1sen!2sin!4v1687516490239!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        
        // </div>

        // <div className="google-map"> 
        //     <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3887.4248592430263!2d77.6528679!3d13.0085947!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae112dc26c90c1%3A0xc06cf8329d7920b3!2sFubeus%20Technology%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1687511886385!5m2!1sen!2sin" width= {window.innerWidth <= "450"? "325" :"570"} height="400" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        //     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2931.7756345813177!2d-71.45744172493563!3d42.70847131302041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3bbd7f84765e5%3A0xa5775306fdf574a0!2sFubeus!5e0!3m2!1sen!2sin!4v1687516490239!5m2!1sen!2sin" width={window.innerWidth <= "450"? "325" :"600"} height="400" style= {{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

        // </div>
        <div class="container mt-5">
            <div class="row">
                <div class="col-md-4 mt-sm-5">
                {/* <   iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.424859022502!2d77.65286787377202!3d13.008594714068753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae112dc26c90c1%3A0xc06cf8329d7920b3!2sFubeus%20Technology%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1690443490244!5m2!1sen!2sin"  height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    <iframe src= "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3887.4248592430263!2d77.6528679!3d13.0085947!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae112dc26c90c1%3A0xc06cf8329d7920b3!2sFubeus%20Technology%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1687511886385!5m2!1sen!2sin" width="100%" height="400" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            <div class="col-md-4 mt-sm-5">
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2931.775634581314!2d-71.45744172497946!3d42.70847131302049!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3bbd7f84765e5%3A0xa5775306fdf574a0!2sFubeus!5e0!3m2!1sen!2sin!4v1690447374728!5m2!1sen!2sin"  height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2931.7756345813177!2d-71.45744172493563!3d42.70847131302041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3bbd7f84765e5%3A0xa5775306fdf574a0!2sFubeus!5e0!3m2!1sen!2sin!4v1687516490239!5m2!1sen!2sin" width="100%" height="400" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div class="col-md-4 mt-sm-5">
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3354.716286855399!2d-96.83587772554736!3d32.77326188418!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e99623917f035%3A0xe16f37086edbdd5f!2s539%20W%20Commerce%20St%20%236876%2C%20Dallas%2C%20TX%2075208%2C%20USA!5e0!3m2!1sen!2sin!4v1690447458960!5m2!1sen!2sin"  height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3354.7162868553846!2d-96.83587772549716!3d32.773261884179945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e99623917f035%3A0xe16f37086edbdd5f!2s539%20W%20Commerce%20St%20%236876%2C%20Dallas%2C%20TX%2075208%2C%20USA!5e0!3m2!1sen!2sin!4v1690280486419!5m2!1sen!2sin" width="100%" height="400" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div><br/><br/>
    </div>
    );
}

export default Map;