import React from "react";
import From from "./From";

const ContactPage = () => {
  return (
    <div className={"contact-page-area-wrapper"} style={{ paddingTop: "30px" }}>
      <div className="container">
        <div className="contact-content-wrap">
          <div className="row">
            <div className="col-lg-8">
              <div className="contact-form-area contact-method">
                <h2 style={{ color: "#5C3C9C" }}>Contact Us</h2>
                <h6>
                  Your email address will not be published. Required fields are
                  marked *
                </h6>
                <From />
              </div>
            </div>

            {/* <div className="col-lg-4">
                            <div className="contact-information contact-method">
                                <div className="contact-info-con">
                                    <h3>Contact Info</h3>
                                    <ContactInfo
                                        address="Fubeus Inc.
                                        1 Tara Boulevard, Suite 200, Nashua, New Hampshire 03062, USA.
                                        Call Us: +1 (408) 709 3678
                                        <br/>
                                        <br/>
                                        Fubeus Inc.
                                        539 W. Commerce St, SUITE 6876, Dallas, Texas 75208, USA
                                        <br/>
                                        <br/>
                                        Fubeus Technology Pvt. Ltd​
                                        Site No. 5M-413, Arka, 3rd Floor, OMBR Layout Main Road, Bengaluru -560043
                                        Call Us: +91-80-41132908"
                                    />
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
