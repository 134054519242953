import React, { useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Thumbnail from "../UI/Thumbnail";
import Content from "../UI/Content";
import applicationSoftwareData from "../../data/ServicePages/applicationSoftware.json";

const Devops = () => {
  const [data] = useState(applicationSoftwareData);

  return (
    <div className="about-area-wrapper sm-top">
      <div className="container">
        <span className="about-us-intro">
          At Fubeus, we drive Agile and DevOps adoption in an integrated way to
          bridge the gap between two fundamental teams i.e Development(Dev) and
          Operations(Ops) to ease the maintenance of products for finer
          collaboration and augmented prolificity. Fubeus excels in building and
          integrating the best DevOps toolchain for end-to-end application
          release thereby enabling reliable releases and faster validation in a
          lower timeframe and cost.
          <br />
          <br />
        </span>

        <div className="devops-service-details">
          <h5 style={{ color: "#5C3C9C" }}>
            <b>DEVOPS CONSULTING</b>
          </h5>
          <p>
            Our capable DevOps team helps you in building your framework and
            integrate them with the industry best practices from scratch to end
            product. We design a tactical plan for reconstructing
            practices/processes, integrating stronger security mechanisms, and
            building a fully automated environment according to your
            requirements.
          </p>
          <h5 style={{ color: "#5C3C9C" }}>
            <b>DEVOPS IMPLEMENTATION</b>
          </h5>
          <p>
            We at Fubeus have extensive experience in implementing CI/CD
            Pipeline, Customization of solution and Provisioning of cloud
            environment for any complex projects. Our solutions have made the
            development process much simpler and have saved hours of operations
            time for our customers.
          </p>
          <h5 style={{ color: "#5C3C9C" }}>
            <b>MANAGED DEVOPS SERVICES</b>
          </h5>
          <p>
            We proceed with the analysis, building, automation and
            implementation in the specified areas and “sway the design”.
            construction, server setup, configuration management, CI/CD, and
            automation along with implementation of monitoring practices,
            feedback practices, and troubleshooting for better performance.
          </p>
          <div
            className="d-flex"
            style={{ alignItem: "center", justifyContent: "center" }}
          >
            <img
              classes="about-thumb"
              src={require("../../assets/img/service/details/DevOPs1.jpg")}
              style={{ height: "420px", width: "630px" }}
            ></img>
          </div>{" "}
          <br />
        </div>
      </div>
    </div>
  );
};

export default Devops;
