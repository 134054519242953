import React from "react";
import parse from "html-react-parser";
import Thumbnail from "../UI/Thumbnail";
import Content from "../UI/Content";

const DetailsType02 = (props) => {
  return (
    <div>
      <div className="row align-items-lg-center">
        <div className="col-md-6 col-lg-7">
          <Content classes="about-content">
            <h5 style={{ color: "#5C3C9C" }}>
              <b>{props.title}</b>
            </h5>
            <p align="justify" style={{ paddingLeft: "10px" }}>
              {parse(props.description)}
            </p>
            {props.title2 ? (
              <h5 style={{ color: "#5C3C9C" }}>
                <b>{props.title2}</b>
              </h5>
            ) : (
              ""
            )}
            {props.description2 ? (
              <p align="justify">{parse(props.description2)}</p>
            ) : (
              ""
            )}
          </Content>
        </div>
        <div className="col-md-6 col-lg-5">
          <Thumbnail
            classes="about-thumb"
            imgSrc={require("../../assets/img/" + props.img)}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsType02;
