import React from 'react';
import quoteIcon from '../../../assets/img/icons/quote.png';
import parse from "html-react-parser";

function TestimonialItem(props) {
    return (
        <div className="testimonial-item testimonial-item--3">
            <div className='client'>
                <div className="testimonial-thumb">
                    <img src={require('../../../assets/img/' + props.authorThumb)} alt="author"/>
                </div>   
                <h5 className="client-name">{parse(props.author)}</h5>
            </div>
            
            <div className="testimonial-txt">
                {/* <img src={quoteIcon} alt="quote"/> */}
                <p>{props.quote}</p>
                
            </div>
        </div>
    );
}

export default TestimonialItem;