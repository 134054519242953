import React, { useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Thumbnail from "../UI/Thumbnail";
import Content from "../UI/Content";
import semiconductor from "../../data/ServicePages/semiconductor.json";
import DetailsType01 from "../Common/DetailsType01";
import DetailsType02 from "../Common/DetailsType02";

const About = () => {
  const [data] = useState(semiconductor);

  return (
    <div className="about-area-wrapper sm-top">
      <div className="container">
        <span className="about-us-intro">
          Our semiconductor services include complete <b>RTL to GDSII</b> &
          validation, verification of board and hardware systems to address the
          industry needs in the domains such as Wireless, Processor, Multimedia,
          automotive, consumer electronics, networking, and telecommunication.
          We are a leading provider of integrated circuit (IC) design and
          verification services including physical design. Our experienced
          engineers take care of understanding customers requirements thoroughly
          before turning them into robust designs.
          <br />
          <br />
        </span>
        <DetailsType01
          img={data[0].thumb}
          title={data[0].title}
          description={data[0].text}
        />
        <DetailsType02
          img={data[1].thumb}
          title={data[1].title}
          description={data[1].text}
        />
        <DetailsType01
          img={data[2].thumb}
          title={data[2].title}
          description={data[2].text}
        />
        <DetailsType02
          img={data[3].thumb}
          title={data[3].title}
          description={data[3].text}
        />
        <DetailsType01
          img={data[4].thumb}
          title={data[4].title}
          description={data[4].text}
        />
        <DetailsType02
          img={data[5].thumb}
          title={data[5].title}
          description={data[5].text}
        />
      </div>
    </div>
  );
};

export default About;
