import React,{Fragment} from 'react';
// import socialNetworks from '../../data/SocialNetworks/socials.json'
import socialNetworks from '../../data/SocialNetworks/fubeus_socials.json'

const ContactInfo = ({address}) => {
    return (
        <Fragment>
            <div className="widget-item m-0">
                <address>
                    <span dangerouslySetInnerHTML={{__html: address}}/>
                </address><br/><br/>
                <p><b>Call Us</b></p>
                <p>US : +1 (408) 709 3678</p>
                <p>India : +91 960 698 3101</p>
                <p><b>Our Mailbox</b></p>
                <p>contact@fubeus.com</p>
            </div>
            <div className="member-social-icons mt-30">
                {
                    socialNetworks.map(social=>(
                        <a key={social.id} href={`https://${social.networkName}.com/${social.username}`} target="_blank" rel="noopener noreferrer">
                            <i className={`fa fa-${social.networkName}`}/>
                        </a>
                    ))
                }
            </div>
        </Fragment>
    );
};

export default ContactInfo;