import React from 'react';
import Form from "./Form";

const ContactPage = () => {
    return (
        <div className={'contact-page-area-wrapper sp-y'}>
            <div className="container">
                <div className="contact-content-wrap">
                    <div className="row">
                        <div className="col-lg-10">
                            <div className="contact-form-area contact-method">
                                <h3 style={{color: '#5C3C9C'}}>Send an Enquiry</h3>
                                <p>Your email address will not be published. Required fields are marked *</p>
                                <Form/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;