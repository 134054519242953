import React, { useState } from "react";
import Thumbnail from "../../UI/Thumbnail";
import private5gCard from "../../../data/Competencies/5g/virtualServices.json";
import CardItem from "../../Common/CardItem";

const ContentType05 = () => {
  return (
    <div className="about-area-wrapper sm-top">
      <div className="container">
        <span align="justify">
          As the world steps into the Network of the future, we are going to
          witness the circulation of massive data streams, implementation of
          unprecedented IOT use-cases and highly immersive AR/ VR experiences.
          Operators all across the world are investing heavily in virtualization
          technologies to evolve their Access network infrastructures for these
          new capabilities. <br />
          <br />
        </span>
        <span align="justify">
          Virtual & Open RAN is a solution that transforms the access network
          from a proprietary hardware-based network to a software-defined
          network, making it more flexible, agile, and cost-effective while
          eliminating vendor lock-ins.
        </span>
        <br /> <br />
        <span align="justify">
          Fubeus’s “Virtual & Open RAN services” enables virtualization of LTE
          Access networks and is an indispensable step into the 5G future as it
          drastically reduces operational costs. It virtualizes network
          functions over COTS servers and enables a smart and effective control
          over network resources. It also enables the seamless deployment of
          additional features and applications towards the edge of the network
        </span>
        <br />
        <br />
        <br />
        <div className="row mtn-35">
          {private5gCard.map((item) => (
            <CardItem
              key={item.id}
              id={item.id}
              title={item.title}
              excerpt={item.excerpt}
              thumb={item.thumb}
              height={item.height}
              width={item.width}
              readmore="true"
            />
          ))}
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default ContentType05;
