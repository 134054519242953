import React, { Fragment } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import CompetenciesRetail from "../components/Competencies/CompetenciesRetail";

const PageServiceDetails = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/about-us-header.jpg")}
        title="Retail"
        content=""
      />
      <CompetenciesRetail />
      <Footer />
    </Fragment>
  );
};

export default PageServiceDetails;
