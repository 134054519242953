import React, {useState} from 'react';
import Thumbnail from "../../UI/Thumbnail";
import private5gCard from '../../../data/Competencies/5g/private5g.json';
import CardItem from "../../Common/CardItem";

const ContentType03 = () => {
    return (
        <div className="about-area-wrapper sm-top">
            <div className="container">
            <div className='d-flex' style={{alignItem : "center" , justifyContent : "center"}}>
                        <img classes="about-thumb" src={require('../../../assets/img/competencies/5g/03.jpg')} width="850" height="400"/>
                </div><br/><br/>
                <span align="justify" >With a dedicated team of experts, we specialize in the development, testing, validation, integration, and management of private 5G networks. Our solutions enable SIs, OEMs, and Telcos to establish secure and high-performance communication infrastructures, free from vendor lock-in, while ensuring minimal total cost of ownership (TCO) for their customers. Leveraging our in-house research and development capabilities, we have already field-tested private network proof of concepts (POCs) ready for commercialization.<br/><br/></span>
                <span align="justify">Fubeus Core Network services portfolio intends to provide the ideal solutions to the core network challenges throughout the network lifecycle that will not only drastically cut-down capital & operational expenditure for our customers but will also introduce agility in their systems and enable new revenue streams.</span><br/> 
                <br/><br/>
                  <div className="row mtn-35">
                    {
                        private5gCard.map(item =>(
                            <CardItem key={item.id} id={item.id} title={item.title} excerpt={item.excerpt} thumb={item.thumb} height = {item.height} width = {item.width} readmore="false"/>
                        ))
                    }
                </div><br/><br/>
            </div>
        </div>
    );
};

export default ContentType03;