import React,{Fragment} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import ContentType04 from '../components/Competencies/5g/contentType04';

const PageServiceDetails = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/about-us-header.jpg')}
                title="5G Lab setup"
                content=""
            />
            <ContentType04/>
            <Footer/>
        </Fragment>
    );
};

export default PageServiceDetails;