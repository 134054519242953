import React from "react";
import SectionTitle from "../UI/SectionTitle";
import CompetenciesItem from "./CompetenciesItem";
import competenciesData from "../../data/Competencies/competencies.json";

import serviceTopBg from "../../assets/img/service/service-bg.jpg";

function Competencies({ classes }) {
  return (
    <div className={`service-area-wrapper ${classes}`}>
      <div
        className="service-area-top"
        style={{ backgroundImage: `url("${serviceTopBg}")` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-5 m-auto text-center">
              <SectionTitle variant="light" title="" heading="Competencies" />
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-area">
        <div className="container">
          <div className="row mtn-30">
            {competenciesData.map((service) => (
              <CompetenciesItem
                key={service.id}
                id={service.id}
                title={service.title}
                text={service.shortDesc}
                thumb={service.thumb}
                url={service.url}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Competencies;
