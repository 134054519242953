import React, {useState} from 'react';
import {Link} from "react-router-dom";
import parse from "html-react-parser";
import Thumbnail from "../UI/Thumbnail";
import Content from "../UI/Content";
import IotData from '../../data/Competencies/iot.json'
import DetailsType02 from '../Common/DetailsType02';

const Iot = () => {

    const [data] = useState(IotData);

    return (
        <div className="about-area-wrapper sm-top">
            <div className="container"> 
                <p>Fubeus is one of the leading names in the IoT domain majorly known for its ingenious solutions and implementation . Having its own Artificial Intelligence (AI) driven IoT Smart Box – FBoX, Fubeus has been accredited in the world of IoT for its technical excellence and innovative inventions.</p>
                <p>Our capabilities across the IoT spectrum is one of the standout USP. Fubeus’ engineers are experienced in IoT System design, Application Development, Cloud Computing, Data analytics, Artificial Intelligence and Machine learning. Our team has complete understanding of Wireless and wired Stack development, Linux and Android framework development capabilities, Security feature implementation and design.</p><br/>
                <DetailsType02 img ={data.img} title = {data.title} description ={data.text}/><br/><br/>
            </div>
        </div>
    );
};

export default Iot;