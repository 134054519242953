import React, { Fragment } from "react";
import Header from "../components/Header";
import ServiceDetails from "../templates/ServiceDetails";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import ProductType1 from "../components/Products/product01";

const PageServiceDetails = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/about-us-header.jpg")}
        title="FBoX"
        content=""
      />
      <ProductType1 />
      <Footer />
    </Fragment>
  );
};

export default PageServiceDetails;
