import React from "react";
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/fubeus-logo-crop.png";
import socialNetworks from "../../data/SocialNetworks/fubeus_socials.json";
import ScrollButton from "./ScrollButton";

function Footer() {
  return (
    <footer className="footer-area sp-bottom">
      <div className="container">
        <div className="row mtn-40">
          <div
            className="col-lg-6 order-4 order-lg-0"
            style={{ marginRight: "55px" }}
          >
            <div className="widget-item">
              <div className="about-widget">
                <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                  <img src={Logo} alt="Logo" height="70px" />
                </Link>

                <Text>
                  Fubeus is not business for competition but to create and
                  showcase possibilities of solutions by believing in Innovation
                  and new challenges. Fubeus gives utmost importance to
                  perfection, credibility and quality in its products and the
                  same is delivered in the engineering solutions provided to our
                  customers and that itself is our trademark.
                </Text>

                <div className="member-social-icons mt-30">
                  {socialNetworks.map((social) => (
                    <a
                      key={social.id}
                      href={`https://${social.networkName}.com/${social.username}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#6651c0" }}
                    >
                      {social.networkName === "twitter" ? (
                        <i className={`fa fa-x-${social.networkName}`} />
                      ) : (
                        <i className={`fa fa-${social.networkName}`} />
                      )}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-lg-2 ml-auto">
            <Widget title="Quick Links">
              <List classes="widget-list">
                <LI>
                  <Link to={`${process.env.PUBLIC_URL + "/"}`}>Home</Link>
                </LI>
                <LI>
                  <Link to={`${process.env.PUBLIC_URL + "/about"}`}>
                    About Us
                  </Link>
                </LI>
                <LI>
                  <Link to={`${process.env.PUBLIC_URL + "/contact"}`}>
                    Contact us
                  </Link>
                </LI>
                <LI>
                  <Link to={`${process.env.PUBLIC_URL + "/life-at-fubeus"}`}>
                    Life at Fubeus
                  </Link>
                </LI>
                <LI>
                  <Link to={`${process.env.PUBLIC_URL + "/blogs"}`}>Blogs</Link>
                </LI>
              </List>
            </Widget>
          </div>
          <div className="col-md-4 col-lg-3">
            <Widget title="our Headquarter" color="#6651c0">
              <address>
                Fubeus Inc. 1 Tara Boulevard, Suite 200, Nashua, New Hampshire
                03062, USA.
              </address>
            </Widget>
          </div>
          <div className="order-4 order-lg-0">
            <hr></hr>
            <Text classes="copyright-txt align-items-center justify-content-center d-flex ">
              &copy; Copyright {new Date().getFullYear()} Designed by{" "}
              <a
                href={`${process.env.PUBLIC_URL + "/"}`}
                style={{ color: "#6651c0" }}
              >
                Fubeus
              </a>
            </Text>
          </div>
        </div>
        <ScrollButton />
      </div>
    </footer>
  );
}

export default Footer;
