import React,{Fragment} from 'react';
import Header from "../components/Header";
import ServiceDetails from "../templates/ServiceDetails";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import CompetenciesStorage from "../components/Competencies/CompetenciesStorage";

const PageServiceDetails = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/about-us-header.jpg')}
                title="Storage"
                content=""
            />
            <CompetenciesStorage/>
            <Footer/>
        </Fragment>
    );
};

export default PageServiceDetails;