import React from "react";
import From from "./From";
import ContactInfo from "./ContactInfo";

const ContactPage = () => {
  return (
    <div className={"contact-page-area-wrapper"}>
      <div className="container">
        <div className="contact-content-wrap">
          <div className="row">
            <div className="col-lg-8">
              <div className="contact-form-area contact-method">
                <p>
                  Give us a call or drop by anytime, we endeavour to answer all
                  enquiries within 24 hours on business days. We will be happy
                  to answer your questions.
                </p>
                <h3 style={{ color: "#5C3C9C" }}>Send an Enquiry</h3>
                <p>
                  Your email address will not be published. Required fields are
                  marked *
                </p>
                <From />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="contact-information contact-method">
                <div className="contact-info-con">
                  <h3>Registered offices</h3>
                  <ContactInfo
                    address="<b>Fubeus Inc.</b><br/>
                                        1 Tara Boulevard, Suite 200,<br/>
                                        Nashua, New Hampshire 03062, USA.
                                        <br/>
                                        <br/>
                                        <b>Fubeus Inc.</b><br/>
                                        539 W. Commerce St, SUITE 6876,<br/>
                                        Dallas, Texas 75208, USA
                                        <br/>
                                        <br/>
                                        <b>Fubeus Technology Pvt. Ltd.,​​</b><br/>
                                        11th Floor, B Block,<br/>
                                        Summit @ Brigade Metropolis,<br/>
                                        Garudachar Palya, Mahadevapura,<br/>
                                        Bengaluru, Karnataka - 560 048"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
