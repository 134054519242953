import React from "react";

function CareerItem(props) {
  const linkedinUrl = props.linkedinUrl;

  return (
    <div className="col-sm-6 col-lg-4">
      <div className="service-item">
        <figure className="service-thumb">
          <a href={linkedinUrl}>
            <img
              src={require("../../assets/img/" + props.thumb)}
              alt={props.title}
            />
          </a>

          <figcaption className="service-txt">
            <h5>{props.title}</h5>
          </figcaption>
        </figure>
        <div className="service-content">
          <div className="service-content-inner">
            <h5>
              <a href={linkedinUrl} className="stretched-link">
                {props.title}
              </a>
            </h5>
            <p>{props.text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareerItem;
