import React,{Fragment} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import Team from "../components/Team/home-two";
import TeamCard from '../components/About/home-one/TeamCards';

const PageAbout = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/about-us-header.jpg')}
                title="About Us"
                content=""
            />
            <Team/>
            <TeamCard/>
            <Footer/>
        </Fragment>
    );
};

export default PageAbout;