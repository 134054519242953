import React, { useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Thumbnail from "../UI/Thumbnail";
import Content from "../UI/Content";
import FboxCard3 from "../../data/Products/fboxCard3.json";
import FboxCard4 from "../../data/Products/fboxCard4.json";
import Features from "../../components/Features";
import ContactPage from "../../templates/ProductContact";
import ProductCard from "./productCard";
import ProductCard2 from "./productCard2";
import CardItem from "../Common/CardItem";
import DetailsType01 from "../Common/DetailsType01";
import DetailsType02 from "../Common/DetailsType02";
import FboxData from "../../data/Products/fbox.json";

const About = () => {
  return (
    <div className="about-area-wrapper sm-top">
      <div className="container">
        <h2 className="text-center" style={{ color: "#5C3C9C" }}>
          FBoX -THE FUTURISTIC BOX
        </h2>
        <span align="justify">
          Fbox is India’s first Artificial Intelligence (AI) driven IoT Smart
          Box which will give you a hassle-free experience with a single box
          solution for multiple applications at Home. Fbox connects TV, Security
          Systems, Smartphones, Tab and Laptop together to create a next generation intelligent home.
          <br />
          <br />
        </span>
        <div className="row align-items-lg-center">
          <div className="col-md-6 col-lg-6">
            <span style={{ color: "#5C3C9C" }}>
              <b>A SELF-EQUIPPED BOX FOR ALL SMART HOME NEEDS</b>
            </span>
            <Thumbnail
              classes="about-thumb"
              imgSrc={require("../../assets/img/products/26.jpg")}
            />
          </div>
          <div className="col-md-6 col-lg-6">
            <span style={{ color: "#5C3C9C" }}>
              <b>TECHNOLOGIES ENABLED</b>
            </span>
            <Thumbnail
              classes="about-thumb"
              imgSrc={require("../../assets/img/products/34.png")}
            />
          </div>
          <br />
        </div>
        <br />
        <div className="row align-items-lg-center">
          <h4 className="text-center" style={{ color: "#5C3C9C" }}>
            <b>PLATFORMS TESTED ON</b>
          </h4>
          <br />
          <div className="row justify-content-around">
            <div className="col-md-4 col-lg-4">
              <Thumbnail
                classes="about-thumb"
                imgSrc={require("../../assets/img/products/29.png")}
              />
              <h6 style={{ color: "#5C3C9C" }}>
                <b>X86 PROCESSOR</b>
              </h6>
            </div>

            <div className="col-md-4 col-lg-4">
              <Thumbnail
                classes="about-thumb"
                imgSrc={require("../../assets/img/products/arm.png")}
              />
              <h6 style={{ color: "#5C3C9C" }}>
                <b>ARM PROCESSOR</b>
              </h6>
            </div>
          </div>
          <br />
        </div>
        <br />
        <h2 className="text-center" style={{ color: "#5C3C9C" }}>
          FEATURES
        </h2>
        <h2 className="text-center" style={{ color: "#5C3C9C" }}>
          Set Top Box(DVB)
        </h2>
        <Features />
        <br />
        <h2 className="text-center" style={{ color: "#5C3C9C" }}>
          HOME AUTOMATION CONTROL SYSTEMS
        </h2>
        <h3 className="text-center" style={{ color: "#5C3C9C" }}>
          AI ENABLED ADOPTIONS
        </h3>
        <span align="justify">
          Our Home Automation system understand you and it will record your
          patterns throughout the day and suggest your pattern.Artificial
          Intelligence enabled Fbox learns the user pattern and predicts user
          behavior and builds situational awareness, or the capacity to
          understand user preferences and modify parameters as needed as well as
          it suggests and creates profile according to the user.
        </span>
        <ProductCard />
        <br />
        <h2 className="text-center" style={{ color: "#5C3C9C" }}>
          AI BASED PERSONALIZATION
        </h2>
        <h3 className="text-center" style={{ color: "#5C3C9C" }}>
          MOODS AND SCHEDULING
        </h3>
        <span align="justify">
          As a human being, we can have different moods at different point of
          time, but it is totally fine we can manage it all for you. We will set
          the perfect lighting, temperature, curtains, and security systems for
          you as per your current mood. Are you a super busy person? You don’t
          want to bother about small things at your home and what if you want to
          switch on your water motor at a scheduled time for scheduled duration
          or your AC, we give you that facility with our smart control.
        </span>
        <ProductCard2 />
        <br />
        <h3 className="text-center" style={{ color: "#5C3C9C" }}>
          <b>DIGITAL VIDEO RECORDING AND HOME SECURITY SYSTEMS</b>
        </h3>
        <br />
        <p align="justify">
          Keep a watch on your kids even while being at your office or keep a
          watch on who enters or leaves your home by Fbox app.
        </p>
        <br />
        <p align="justify">
          Security being one of the most important features in a home, Fbox
          gives support for Home Security surveillance, where one can connect up
          to 4 generic CCTV cameras to the application without having a separate
          DVR for each.
        </p>
        <br />
        <p align="justify">
          Users will be able to watch the near and dear ones from anywhere in
          the world anytime.
        </p>
        <div
          className="d-flex"
          style={{ alignItem: "center", justifyContent: "center" }}
        >
          <img
            classes="about-thumb"
            src={require("../../assets/img/products/23.png")}
            style={{ height: "420px", width: "760px" }}
          ></img>
        </div>{" "}
        <br />
        <div
          className="d-flex"
          style={{ alignItem: "center", justifyContent: "center" }}
        >
          <img
            classes="about-thumb"
            src={require("../../assets/img/products/24.png")}
            style={{ height: "420px", width: "760px" }}
          ></img>
        </div>{" "}
        <br />
        <h3 className="text-center" style={{ color: "#5C3C9C" }}>
          <b>OTHER FEATURES</b>
        </h3>
        <br />
        <div className="row mtn-35">
          {FboxCard3.map((item) => (
            <CardItem
              key={item.id}
              id={item.id}
              title={item.title}
              excerpt={item.excerpt}
              thumb={item.thumb}
              height={item.height}
              width={item.width}
              style={{ backgroundColor: "#d2cce4" }}
            />
          ))}
        </div>
        <br />
        <br />
        <br />
        <DetailsType01
          img={FboxData[0].thumb}
          title={FboxData[0].title}
          description={FboxData[0].text}
        />
        <DetailsType02
          img={FboxData[1].thumb}
          title={FboxData[1].title}
          description={FboxData[1].text}
        />
        <div className="row align-items-lg-center">
          <div className="row mtn-35">
            {FboxCard4.map((item) => (
              <CardItem
                key={item.id}
                id={item.id}
                title={item.title}
                excerpt={item.excerpt}
                thumb={item.thumb}
                height={item.height}
                width={item.width}
                cols="col-md-6 col-lg-6"
              />
            ))}
          </div>
        </div>
        <ContactPage />
      </div>
    </div>
  );
};

export default About;
