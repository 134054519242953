import React,{Fragment} from 'react';
import Header from "../components/Header";
import BlogPage from "../templates/Blog";
import Footer from "../components/Footer";

const PageBlogGrid = () => {
    return (
        <Fragment>
            <Header/>
            <BlogPage blog_type={'grid'} sidebar={false} sidebar_position={'left'}/>
            <Footer/>
        </Fragment>
    );
};

export default PageBlogGrid;