import React, {useState} from 'react';
import {Link} from "react-router-dom";
import parse from "html-react-parser";
import Thumbnail from "../UI/Thumbnail";
import Content from "../UI/Content";
import RetailAppData from '../../data/Competencies/retail-app-dev.json'
import DetailsType01 from '../Common/DetailsType01';
import DetailsType02 from '../Common/DetailsType02';

const Retail = () => {
    const [data] = useState(RetailAppData);

    return (
        <div className="about-area-wrapper sm-top">
            <div className="container">
                <h2 className='text-center' style={{color: '#5C3C9C'}}>RETAIL SOFTWARE SOLUTIONS</h2>
                <span align="justify">Fubeus has expertise and delivered various retail software products to empower brick-and-mortars to compete with ecommerce platforms. We give retailers the tools for providing seamless customer experience and personalized service across various channels, be it in- or near-store, web, or mobile.<br/><br/></span>
                <h3 className='text-center' style={{color: '#5C3C9C'}}><b>RETAIL APP DEVELOPMENT</b></h3>
                <DetailsType01 img ={data[0].img} title ={data[0].title} title2 ={data[0].title2} description={data[0].text} description2={data[0].text2}/>
                <DetailsType02 img ={data[1].img} title ={data[1].title} title2 ={data[1].title2} description={data[1].text} description2={data[1].text2} />
                <h3 className='text-center' style={{color: '#5C3C9C'}}><b>END-TO-END RETAIL SOFTWARE DEVELOPMENT</b></h3>
                <DetailsType01 img ={data[2].img} title ={data[2].title} title2 ={data[2].title2} description={data[2].text} description2={data[2].text2}/>
                <DetailsType02 img ={data[3].img} title ={data[3].title} title2 ={data[3].title2} description={data[3].text} description2={data[3].text2} />
                <DetailsType01 img ={data[4].img} title ={data[4].title} title2 ={data[4].title2} description={data[4].text} description2={data[4].text2}/>
                <DetailsType02 img ={data[5].img} title ={data[5].title} title2 ={data[5].title2} description={data[5].text} description2={data[5].text2} />
                <h3 className='text-center' style={{color: '#5C3C9C'}}><b>ECOMMERCE DEVELOPMENT</b></h3>
                <DetailsType01 img ={data[6].img} title ={data[6].title} title2 ={data[6].title2} description={data[6].text} description2={data[6].text2}/>
            </div>
        </div>
    );
};

export default Retail;