import React, { useState } from "react";
import modemRanCard from "../../../data/Competencies/5g/modemRan.json";
import FiveGCards from "./5gcards";
import FiveGCardsData from "../../../data/Competencies/5g/5gCards.json";

const ContentType01 = () => {
  const [data] = useState(modemRanCard);

  return (
    <div className="about-area-wrapper sm-top">
      <div className="container">
        <span align="justify">
          Our team of engineers is unique in the industry with extensive
          experience from the key stack development teams at major telecom
          software companies. They played key roles in innovating, developing,
          implementing and testing L1, L2, L3 layer for 802.11a/b/g/n/ac/ad,
          IPv4, IPv6, OSPF, RIP, STP VLANs and so on. <br />
          <br />
        </span>
        <span align="justify">
          Fubeus is one of the leading service companies in the world in terms
          of development and testing of 4G/5G software across L2 and L3. Fubeus
          has been there since the start of 5G NR technology, and gathered
          experience in development and testing of 5G all around the globe in
          several countries (namely USA, India, UK, Europe).
        </span>
        <br />
        <br />
        <span align="justify">
          Fubeus is well equipped at all major continents and cities to carry
          out Development and Testing of 5G RAN and UE Protocol stack with
          respect to 5G SA and NSA architecture. Fubeus is capable of carrying
          out lab tests, drive tests, Field tests, E2E call flow test and
          automation test of 5G SA and NSA technology. Our team of experienced
          project managers, and engineers are always up to date and capable of
          executing and servicing of 5G projects.
        </span>
        <br />
        <br />
        <span align="justify">
          We have an expertise in working of on call and packet processing, OAM,
          protocol stacks, BSP, hardware and OS abstraction, network simulators,
          traffic generators, and RF tools across the entire product lifecycle.
        </span>
        <br />
        <br />
        <span align="justify">
          Fubeus offers services in turnkey mode or even can take up the
          ownership of projects. Our solutions and services include design,
          development, integration, testing and maintenance of your stack for
          UE, RAN or EPC for 4G/5G.
        </span>
        <br />
        <br />

        <div className="row mtn-30">
          {FiveGCardsData.map((service) => (
            <FiveGCards
              key={service.id}
              url={service.url}
              title={service.title}
              text={service.shortDesc}
              thumb={service.thumb}
            />
          ))}
        </div>
      </div>
      <br />
    </div>
  );
};

export default ContentType01;
