import React, { useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Thumbnail from "../UI/Thumbnail";
import Content from "../UI/Content";
import DetailsType01 from "../Common/DetailsType01";
import DetailsType02 from "../Common/DetailsType02";

const ApplicationSoftware = (props) => {
  const data = props.data;
  const intro = props.intro;
  return (
    <div className="about-area-wrapper sm-top">
      <div className="container">
        <span className="about-us-intro" align="justify">
          {intro}
          <br />
          <br />
        </span>
        <DetailsType01
          img={data[0].thumb}
          title={data[0].title}
          description={data[0].text}
        />
        <DetailsType02
          img={data[1].thumb}
          title={data[1].title}
          description={data[1].text}
        />
      </div>
      <br />
      <br />
    </div>
  );
};

export default ApplicationSoftware;
