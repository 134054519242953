import React from "react";

const NodeDevelopemntContentWrapper = ({ post, sidebar }) => {
  return (
    <article className="blog-post-details">
      <div className="blog-post-txt-wrap">
        <div className="row">
          {!sidebar ? (
            <div className="col-md-3 col-lg-2 order-1 order-md-0">
              <div className="author-info mt-sm-40">
                <div className="author-thumb">
                  <img
                    src={require("../../assets/img/" + post.author.proPic)}
                    alt={post.author.name}
                  />
                </div>
                <div className="author-txt">
                  <h5>
                    {post.author.name}{" "}
                    <span className="designation">
                      {post.author.designation}
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          ) : null}

          <div
            className={`m-auto order-0 ${
              !sidebar ? "col-md-9 col-lg-8" : "col-12"
            }`}
          >
            <div className="blog-post-txt">
              <h3 className="text-center">
                <b>{post.title}</b>
              </h3>
              <div
                className="d-flex"
                style={{ alignItem: "center", justifyContent: "center" }}
              >
                <img
                  classes="about-thumb"
                  src={require("../../assets/img/blog/24.png")}
                  style={{ height: "350px", width: "550px" }}
                  alt="blog"
                ></img>
              </div>{" "}
              <br />
              <div
                className="d-flex"
                style={{ alignItem: "center", justifyContent: "center" }}
              >
                <img
                  classes="about-thumb"
                  src={require("../../assets/img/blog/25.png")}
                  style={{ height: "350px", width: "550px" }}
                  alt="blog"
                ></img>
              </div>{" "}
              <br />
              <h4>Standalone option 5</h4>
              <ul style={{ paddingLeft: "10px" }}>
                <li style={{ listStyle: "disc" }}>
                  <b>NGC (Next Generation Core Network):</b> Consists of AMF,
                  SMF and UPF. It is also called as 5GC(5G Core Network)
                </li>
                <li style={{ listStyle: "disc" }}>
                  <b>AMF (Access and Mobility Management Function):</b>{" "}
                  Responsible for Registration, Connection, Reachability and
                  Mobility Management, access authentication and authorization,
                  and location services.
                </li>
                <li style={{ listStyle: "disc" }}>
                  <b>SMF (Session Management Function):</b> Responsible for PDU
                  Session Management, IP Address Allocation. GTP-U Tunnel
                  Management and Downlink Notification Management.
                </li>
                <li style={{ listStyle: "disc" }}>
                  <b>UPF (User Plane Function):</b> Forwards traffic between RAN
                  and the Internet, packet forwarding, it is responsible for
                  policy enforcement, lawful intercept, traffic usage reporting,
                  and QoS policing.
                </li>
                <li style={{ listStyle: "disc" }}>
                  <b>NG-RAN(Next Generation Radio Access Network):</b> Consists
                  of gNB/gNodeB(5G Base Station)
                </li>
                <li style={{ listStyle: "disc" }}>
                  <b>gNB (gNodeB):</b> A 5G Base Station is known as a gNodeB.
                  It uses the New Radio (NR) air-interface and signalling
                  protocols towards the end-user device. The gNB connects to an
                  AMF for control plane signalling. The gNB connects to an UPF
                  for the transfer of application data.
                </li>
                <li style={{ listStyle: "disc" }}>
                  <b>ng-eNB (ng-eNodeB):</b> Is connected to the 5G core
                  network, i.e., an upgraded 4G Base Station. It connects to UE
                  through LTE air interface and protocols. It is connected to 5G
                  core network via NG-C and NG-U interface, i.e., the ng-eNodeB
                  (ng-eNB) is capable of supporting 5G Core Network signalling
                  procedure and is also capable of transferring application data
                  to and from a UPF (User Place Functions).
                </li>
                <li style={{ listStyle: "disc" }}>
                  <b>NG interface</b> exists between 5GC and the base stations
                  (i.e. gNB & ng-eNB).
                </li>
                <li style={{ listStyle: "disc" }}>
                  <b>Xn interface</b> exists between the base stations. Between
                  gNB-gNB, between (gNB)-(ng-eNB) and between (ng-eNB)-(ng-eNB).
                </li>
              </ul>
              <h3>
                <b>5G UPPER AND LOWER LAYER SPLIT ARCHITECTURE</b>
              </h3>
              <div
                className="d-flex"
                style={{ alignItem: "center", justifyContent: "center" }}
              >
                <img
                  classes="about-thumb"
                  src={require("../../assets/img/blog/26.png")}
                  style={{ height: "306px", width: "607px" }}
                  alt="blog"
                ></img>
              </div>{" "}
              <br />
              <ul style={{ paddingLeft: "10px" }}>
                <li style={{ listStyle: "disc" }}>
                  The Transport network which connects the Base Station to the
                  Core Network is known as the Backhaul.
                </li>
                <li style={{ listStyle: "disc" }}>
                  The Connection between the Centralized Unit (CU) and the
                  Distributed Unit (DU) is known as Mid haul.
                </li>
                <li style={{ listStyle: "disc" }}>
                  The Connection between the Radio Access Unit and the Radio
                  Unit (RU) is known as Front haul.
                </li>
              </ul>
              <h3>
                <b>5G CU/DU SPLIT BASE STATION ARCHITECTURE</b>
              </h3>
              <div
                className="d-flex"
                style={{ alignItem: "center", justifyContent: "center" }}
              >
                <img
                  classes="about-thumb"
                  src={require("../../assets/img/blog/27.png")}
                  style={{ height: "306px", width: "607px" }}
                  alt="blog"
                ></img>
              </div>{" "}
              <br />
              <ul style={{ paddingLeft: "10px" }}>
                <li style={{ listStyle: "disc" }}>
                  The 5G Core Network consists of many components like, AMF,
                  SMF, UPF, AUSF, UDM, PCF, NSSF and AF. And it can
                  connect/Manage multiple gNB(gNodeB).
                </li>
                <li style={{ listStyle: "disc" }}>
                  The gNB-CU is Centralized Unit(CU) and it can connect to
                  multiple DU’s(Distributed Unit’s)
                </li>
                <li style={{ listStyle: "disc" }}>
                  The gNB-DU is Distributed Unit (DU) and it can manage multiple
                  Radio Units (RU’s).
                </li>
              </ul>
              <h3>
                <b>5G CONTROL PLANE – USER PLANE SEPARATION ARCHITECTURE</b>
              </h3>
              <div
                className="d-flex"
                style={{ alignItem: "center", justifyContent: "center" }}
              >
                <img
                  classes="about-thumb"
                  src={require("../../assets/img/blog/28.png")}
                  style={{ height: "306px", width: "607px" }}
                  alt="blog"
                ></img>
              </div>{" "}
              <br />
              <ul style={{ paddingLeft: "10px" }}>
                <li style={{ listStyle: "disc" }}>
                  The gNB-CU is further split into gNB-CU-CP and gNB-CU-UP. This
                  separation allows independent scaling of the Base Station
                  hardware. The CU-UP capability can be increased to support
                  higher throughputs, without having to increase the capability
                  and it minimize the latency towards DU (Distributed Unit).
                </li>
                <li style={{ listStyle: "disc" }}>
                  The CU-CP includes RRC and PDCP protocol stack layers and is
                  responsible for RRC Signaling.
                </li>
                <li style={{ listStyle: "disc" }}>
                  The CU-UP includes SDAP and PDCP protocol stack layers and is
                  responsible for handling application data.
                </li>
                <li style={{ listStyle: "disc" }}>
                  The DU includes RLC, MAC and L1 protocol stack layers and is
                  responsible for scheduling the signaling data which comes from
                  CU-CP and scheduling the application data which comes from
                  CU-UP to the User Equipment (UE).
                </li>
              </ul>
              <h3>
                <b>CU-UP SEPARATION ARCHITECTURE</b>
              </h3>
              <div
                className="d-flex"
                style={{ alignItem: "center", justifyContent: "center" }}
              >
                <img
                  classes="about-thumb"
                  src={require("../../assets/img/blog/29.png")}
                  style={{ height: "306px", width: "607px" }}
                  alt="blog"
                ></img>
              </div>{" "}
              <br />
              <ul style={{ paddingLeft: "10px" }}>
                <li style={{ listStyle: "disc" }}>
                  The CU-UP separation can include single CU-CP, Multiple CU-UP
                  and Multiple DU.
                </li>
                <li style={{ listStyle: "disc" }}>
                  The connection of multiple CU-UP to a single DU means that
                  each cell can have a choice of CU-UP.
                </li>
                <li style={{ listStyle: "disc" }}>
                  A first CU-UP could be used for eMBB application, the second
                  can be used for URLLC application, The selection of
                  appropriate CU-UP can form part of a Network Slicing
                  implementation.
                </li>
                <li style={{ listStyle: "disc" }}>
                  The CU-CP and CU-UP are connected using E1 interface and it is
                  used for only control plane signaling
                </li>
                <li style={{ listStyle: "disc" }}>
                  The CU-CP and the DU are connected using F1-C interface and it
                  is used for only control plane signaling.
                </li>
                <li style={{ listStyle: "disc" }}>
                  The CU-UP and the DU are connected using F1-U interface and it
                  is used only for transfer application data.
                </li>
                <li style={{ listStyle: "disc" }}>
                  The CU-UP is responsible for allocating GTP Tunnel Endpoint
                  Identifiers(GTP TEID) for the uplink of the F1-U interface and
                  it is also responsible for allocating GTP TEID for the
                  downlink of the NG-U interface.
                </li>
              </ul>
              <h3>
                <b>Interfaces</b>
              </h3>
              <ul style={{ paddingLeft: "10px" }}>
                <li style={{ listStyle: "disc" }}>
                  NGAP interface exists between 5GC and the base stations (i.e.
                  gNB & ng-eNB)
                </li>
                <ul>
                  <li style={{ listStyle: "disc", marginLeft: "45px" }}>
                    NG-C: Control plane interface between NG-RAN and 5GC.
                  </li>
                  <li style={{ listStyle: "disc", marginLeft: "45px" }}>
                    NG-U: User plane interface between NG-RAN and 5GC.
                  </li>
                </ul>
                <li style={{ listStyle: "disc" }}>
                  XnAP interface exists between the base stations. between
                  gNB-gNB, between (gNB)-(ng-eNB) and between (ng-eNB)-(ng-eNB).
                </li>
                <ul>
                  <li style={{ listStyle: "disc", marginLeft: "45px" }}>
                    Xn is the network interface between NG-RAN nodes.
                  </li>
                  <li style={{ listStyle: "disc", marginLeft: "45px" }}>
                    Xn-U stands for Xn User Plane interface and Xn-C stands for
                    Xn Control Plane interface.
                  </li>
                </ul>
                <li style={{ listStyle: "disc" }}>
                  E1AP interface is point-to-point interface between a gNB-CU-CP
                  and a gNB-CU-UP
                </li>
                <ul style={{ paddingLeft: "10px" }}>
                  <li style={{ listStyle: "disc", marginLeft: "45px" }}>
                    E1 interface supports the exchange of signaling information
                    between the gNB-CU-CP and gNB-CU-UP.
                  </li>
                  <li style={{ listStyle: "disc", marginLeft: "45px" }}>
                    F1-C: Control plane interface between CU-CP and DU
                  </li>
                  <li style={{ listStyle: "disc", marginLeft: "45px" }}>
                    F1-U: User plane interface between CU-UP and DU.
                  </li>
                </ul>
                <li style={{ listStyle: "disc" }}>
                  X2AP interface exists Between en-gNB’s
                </li>
                <ul style={{ paddingLeft: "10px" }}>
                  <li style={{ listStyle: "disc", marginLeft: "45px" }}>
                    X2-C: Control plane interface between en-gNB’s.
                  </li>
                  <li style={{ listStyle: "disc", marginLeft: "45px" }}>
                    X2-U: User plane interface between en-gNB’s.
                  </li>
                </ul>
              </ul>
            </div>

            <div className="share-article text-center">
              <h6>Share this article</h6>
              <div className="member-social-icons justify-content-center">
                <a
                  className="facebook"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                >
                  <i className="fa fa-facebook" />
                </a>
                <a
                  className="twitter"
                  href={`https://twitter.com/home?status=${window.location.href}`}
                >
                  <i className="fa fa-x-twitter" />
                </a>
                <a
                  className="reddit"
                  href={`http://reddit.com/submit?url=${window.location.href}&title=${post.title}`}
                >
                  <i className="fa fa-reddit" />
                </a>
                <a
                  className="pinterest"
                  href={`https://pinterest.com/pin/create/button/?url=${window.location.href}&media=&description=`}
                >
                  <i className="fa fa-pinterest" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default NodeDevelopemntContentWrapper;
