import React, {useState} from 'react';
import {Link} from "react-router-dom";
import parse from "html-react-parser";
import Thumbnail from "../UI/Thumbnail";
import Content from "../UI/Content";
import semiconductor from '../../data/ServicePages/semiconductor.json'
import Features from '../../components/Features'
import ContactPage from '../../templates/ProductContact';

const About = () => {

    const [data] = useState(semiconductor);

    return (
        <div className="about-area-wrapper sm-top">
            <div className="container">
                <div className="row align-items-lg-center">     
                    <div className="col-md-6 col-lg-7">
                        <Content classes="about-content">
                            <h5 style={{color: '#5C3C9C'}}><b>ANDROID DVB STACK</b></h5>
                            <p align="justify">Android DVB stack indigenously developed by Fubeus comes with an amazing service of connecting upto 4 different devices with a single DVB and allows users to watch different channels at different devices at the same time by only one DVB.</p><br/>
                            <p align="justify">One can play and record different channels at the same time, also features like pause, intelligent content analytics, electronic program guide, parental control, favorites and many more are supported by Android DVB Stack.</p>
                        </Content>
                    </div>
                    <div className="col-md-6 col-lg-5">
                        <Thumbnail classes="about-thumb" imgSrc={require('../../assets/img/products/01.png')}/>
                    </div> 
                    <br/> 
                </div>

                <div className="row align-items-lg-center">
                    <div className="col-md-6 col-lg-5">
                        <Thumbnail classes="about-thumb" imgSrc={require('../../assets/img/products/02.png')}/>
                    </div>
                    
                    <div className="col-md-6 col-lg-7">
                        <Content classes="about-content">
                            <p>Fubeus offers an end-to-end STB solution as well as STB stack for Android platforms. The Android STB platform supports a broad range of application and service development for the STB to deliver personalized TV and OTT experiences, including live broadcast, airing of recorded content, and personal interaction with the user. The solution works well with DVB-C, T, S broadcasting systems. It is backed up by a robust STB engine which provides support to an interactive user application with single and multiple user scenarios.</p>
                        </Content>
                    </div>
                </div>
                <br/><br/>
                <p>The solution runs on the latest Android OS and provides distinguishing proprietary features like TV on your mobile/tablet from your setup box, VOD etc. The STB framework improves user scenarios based on the user’s interaction and develops profiles for registered users. It takes utmost care of the user’s privacy without sharing any user data to any external server. Personal users’ data are secured through encryption and are never shared with any third party.</p><br/><br/>              
                <h2 className='text-center' style={{color: '#5C3C9C'}}><b>FEATURES OF DVB</b></h2>
                <Features/>
                <ContactPage/>
            </div>
        </div>
    );
};

export default About;