import React,{Fragment, useState} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import PageWrapper from "../components/PageWrapper";
import DualConnectivityDetails from "../templates/BlogDetails/DualConnectivityDetails";
import BlogData from "../data/Blog/blog.json";


const PageBlog = () => {
    const post = useState(BlogData[1]);
    return (
        <Fragment>
            <Header/>
            <PageWrapper classes={'blog-details-page-content sp-y'}>
                <div className={'col-12' }>
                    <DualConnectivityDetails sidebar={false} post={post[0]}/>
                </div>
            </PageWrapper>
            <Footer/>
        </Fragment>
    );
};

export default PageBlog;