import React from 'react';
import networkCloudCard from '../../../data/Competencies/5g/networkCloud.json';
import CardItem from "../../Common/CardItem";

const ContentType06 = () => {
    return (
        <div className="about-area-wrapper sm-top">
            <div className="container">
                <span align="justify" >CSPs all over the world are investing heavily to migrate their software, applications and infrastructures to cloud. They aim to avail reduction in operational costs and improvements in business continuity, scalability and efficiency. The surge in data consumption and end user demands have impelled the operators to search for solutions that can virtualize their existing network infrastructures with new open-source and automation technologies.<br/><br/></span>
                <span align="justify">Fubeus’s Network cloud and Orchestration services provides the perfect blend of customized solutions to mitigate the business challenges face by operators. We possess the capability to design a pre-integrated production ready network cloud infrastructure and recommend best-suited orchestration, service assurance and CI/ CD automation solutions depending on the CSP’s in-house capability and GTM strategy. The ensuing benefits include higher elasticity and improved service agility.</span><br/> <br/>
                <br/><br/>
                  <div className="row mtn-35">
                    {
                        networkCloudCard.map(item =>(
                            <CardItem key={item.id} id={item.id} title={item.title} excerpt={item.excerpt} thumb={item.thumb} height = {item.height} width = {item.width}/>
                        ))
                    }
                </div><br/><br/>
            </div>
        </div>
    );
};

export default ContentType06;