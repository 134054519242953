import React from "react";
import SectionTitle from "../UI/SectionTitle";
import CareerItem from "./Career";
import careers from "../../data/Career/career.json";
import serviceTopBg from "../../assets/img/service/service-bg.jpg";
import ContactPage from "../../templates/CareerContact";

function Career({ classes }) {
  const linkedinUrl = "https://linkedin.com/company/fubeus";
  return (
    <div className={`service-area-wrapper ${classes}`}>
      <div
        className="service-area-top"
        style={{ backgroundImage: `url("${serviceTopBg}")` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-5 m-auto text-center">
              <SectionTitle variant="light" title="" heading="Apply For Jobs" />
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-area">
        <div className="container">
          <div className="row mtn-30">
            {careers.map((service) => (
              <CareerItem
                key={service.id}
                id={service.id}
                title={service.title}
                text={service.shortDesc}
                thumb={service.thumb}
                linkedinUrl={linkedinUrl}
              />
            ))}
          </div>
        </div>
      </div>
      <br />
      <a
        href={linkedinUrl}
        className="btn btn-brand"
        style={{ alignItem: "center", justifyContent: "center" }}
      >
        Click here for more openings
      </a>
      <ContactPage />
    </div>
  );
}

export default Career;
