import React, { useState } from "react";
import FintechCards from "../../data/Competencies/fintechCards.json";
import FintechCards2 from "../../data/Competencies/fintechCards2.json";
import FintechData from "../../data/Competencies/fintech.json";
import DetailsType01 from "../Common/DetailsType01";
import DetailsType02 from "../Common/DetailsType02";
import CardItem from "../Common/CardItem";

const Fintech = () => {
  const [data] = useState(FintechData);

  return (
    <div className="about-area-wrapper sm-top">
      <div className="container">
        <h2 className="text-center" style={{ color: "#5C3C9C" }}>
          FINANCIAL SOFTWARE TECHNOLOGY (FINTECH)
        </h2>
        <span align="justify">
          Fubeus is a leading provider of custom software solutions and related
          services to financial and fintech organizations, including banks,
          credit unions, and all types of enterprises that look to establish
          effective, digitally-enabled financial processes. Our goal is to be an
          integral part of each customer’s management team; providing training
          and support as needed in order to achieve optimal ROI with our
          products and services.
          <br />
          <br />
        </span>
        <div
          className="d-flex"
          style={{ alignItem: "center", justifyContent: "center" }}
        >
          <img
            classes="about-thumb"
            src={require("../../assets/img/competencies/fintech/fintech-main.png")}
            style={{ height: "420px", width: "760px" }}
          ></img>
        </div>{" "}
        <br />
        <div className="row mtn-35">
          {FintechCards.map((item) => (
            <CardItem
              key={item.id}
              id={item.id}
              title={item.title}
              excerpt={item.excerpt}
              thumb={item.thumb}
              height={item.height}
              width={item.width}
              style={{ height: "250px", backgroundColor: "#d2cce4" }}
            />
          ))}
        </div>
        <br />
        <br />
        <h4 style={{ color: "#5C3C9C" }}>
          <b>CORPORATE FINANCE SOFTWARE SOLUTIONS</b>
        </h4>
        <p align="justify">
          Fubeus develops fintech software solutions integrated with corporate
          infrastructures and enterprise systems to consolidate financial data,
          automate its management, and provide accurate information for
          decision-making.
        </p>
        <br />
        <p>We provide our extensive services in</p>
        <ul style={{ paddingLeft: "10px" }}>
          <li style={{ listStyle: "disc" }}>
            Management of accounts payable and receivable
          </li>
          <li style={{ listStyle: "disc" }}>Expense and revenue management</li>
          <li style={{ listStyle: "disc" }}>Invoice processing</li>
          <li style={{ listStyle: "disc" }}>Tax payment processing</li>
          <li style={{ listStyle: "disc" }}>Payroll management</li>
          <li style={{ listStyle: "disc" }}>Financial risk management</li>
          <li style={{ listStyle: "disc" }}>Planning and forecasting</li>
        </ul>
        <br />
        <h4 style={{ color: "#5C3C9C" }}>
          <b>PERSONAL FINANCE SOFTWARE SOLUTIONS</b>
        </h4>
        <p align="justify">
          Fubeus builds solutions that enable end users to manage and organize
          their personal finances, track spending behavior, monitor daily
          expenses, review and forecast savings, as well as set up reasonable
          and transparent financial goals.
        </p>
        <br />
        <p>We provide our extensive services in</p>
        <ul style={{ paddingLeft: "10px" }}>
          <li style={{ listStyle: "disc" }}>
            Monitoring and optimization of personal expenses
          </li>
          <li style={{ listStyle: "disc" }}>
            Smart advisors and recommendations
          </li>
          <li style={{ listStyle: "disc" }}>Personal budget analysis</li>
          <li style={{ listStyle: "disc" }}>Tax payment processing</li>
          <li style={{ listStyle: "disc" }}>
            Real-time reports and built-in analytics
          </li>
          <li style={{ listStyle: "disc" }}>Budget estimation and planning</li>
          <li style={{ listStyle: "disc" }}>
            Financial notifications and alerts
          </li>
        </ul>
        <br />
        <h4 style={{ color: "#5C3C9C" }}>
          <b>INVESTMENT MANAGEMENT SOFTWARE</b>
        </h4>
        <p align="justify">
          Fubeus provides fintech software development services to deliver
          investment management software that helps aggregate and process
          real-time market data, manage investment portfolios, analyze
          investment risks, and make decisions on the optimal investment time
          and amount.
        </p>
        <br />
        <h3 className="text-center" style={{ color: "#5C3C9C" }}>
          <b>BILLING & PAYMENT SOLUTIONS</b>
        </h3>
        <br />
        <DetailsType01
          img={data[0].img}
          title={data[0].title}
          title2={data[0].title2}
          description={data[0].text}
          description2={data[0].text2}
        />
        <div className="row mtn-35">
          {FintechCards2.map((item) => (
            <CardItem
              key={item.id}
              id={item.id}
              title={item.title}
              excerpt={item.excerpt}
              thumb={item.thumb}
              height={item.height}
              width={item.width}
            />
          ))}
        </div>
        <br />
        <br />
        <DetailsType02
          img={data[1].img}
          title={data[1].title}
          title2={data[1].title2}
          description={data[1].text}
          description2={data[1].text2}
        />
        <DetailsType01
          img={data[2].img}
          title={data[2].title}
          title2={data[2].title2}
          description={data[2].text}
          description2={data[2].text2}
        />
        <DetailsType02
          img={data[3].img}
          title={data[3].title}
          title2={data[3].title2}
          description={data[3].text}
          description2={data[3].text2}
        />
        <DetailsType01
          img={data[4].img}
          title={data[4].title}
          title2={data[4].title2}
          description={data[4].text}
          description2={data[4].text2}
        />
        <DetailsType02
          img={data[5].img}
          title={data[5].title}
          title2={data[5].title2}
          description={data[5].text}
          description2={data[5].text2}
        />
        <DetailsType01
          img={data[6].img}
          title={data[6].title}
          title2={data[6].title2}
          description={data[6].text}
          description2={data[6].text2}
        />
      </div>
    </div>
  );
};

export default Fintech;
