import React,{Fragment, useState} from 'react';
import Header from "../components/Header";
import BlogDetailsPage from "../templates/BlogDetails";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import PageWrapper from "../components/PageWrapper";
import FivegRanArchDetails from "../templates/BlogDetails/5gRanArchDetails";
import BlogData from "../data/Blog/blog.json";


const PageBlog = () => {
    const post = useState(BlogData[2]);
    return (
        <Fragment>
            <Header/>
            <PageWrapper classes={'blog-details-page-content sp-y'}>
                <div className={'col-12' }>
                    <FivegRanArchDetails sidebar={false} post={post[0]}/>
                </div>
            </PageWrapper>
            <Footer/>
        </Fragment>
    );
};

export default PageBlog;