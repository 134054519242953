import React, { useState } from "react";
import Thumbnail from "../../UI/Thumbnail";
import modemRanCard from "../../../data/Competencies/5g/modemRan.json";
// import ranTestingCard from "../../../data/Competencies/5g/ranTestingCard.json";
import ranTestingCard from "../../../data/Competencies/5g/ranTestingCard.json";
import CardItem from "../../Common/CardItem";

const ContentType01 = () => {
  // const [data] = useState(modemRanCard);
  console.log(ranTestingCard);

  return (
    <div className="about-area-wrapper sm-top">
      <div className="container">
        <span align="justify">
          5G modem/RAN software development and system integration
          <br />
          <br />
        </span>
        <div
          className="d-flex"
          style={{ alignItem: "center", justifyContent: "center" }}
        >
          <Thumbnail
            classes="about-thumb"
            imgSrc={require("../../../assets/img/competencies/5g/01.png")}
          />
        </div>
        <span>
          Fubeus collaborates with several global clients to provide services to
          their development, maintenance, testing and system integration needs,
          including network services, initial deployments, cloud migrations, or
          other services like 5G lab setup.
        </span>
        <br />
        <span>
          We provide reliable end-to-end development, testing and system
          integration services across technologies.Our engineering team globally
          assist clients in next-gen technologies like 5G (modem, RAN, Core
          network), Private 5G, ORAN, MEC, and ONAP. Our wide range of services
          includes:
        </span>
        <span>
          Our services on Modem and Radio Access Network span from development,
          maintenance, stack porting, adaptation, integration, and testing:
        </span>
        <ul style={{ margin: "30px" }}>
          <li style={{ listStyle: "disc" }}>
            Protocol Stack Development, maintenance and system integration :
          </li>
          <li style={{ listStyle: "disc" }}>
            Complete platform software maintenance for board support and
            engineering
          </li>
          <li style={{ listStyle: "disc" }}>
            Automation of test methods to improve validation and Productization
            support
          </li>
          <li style={{ listStyle: "disc" }}>
            Test coverage improvement and continuous validation
          </li>
          <li style={{ listStyle: "disc" }}>Bug fix and customer support</li>
          <li style={{ listStyle: "disc" }}>
            Radio L2/L3 porting on 3rd party hardware
          </li>
          <li style={{ listStyle: "disc" }}>
            Stack adaptation layers for API interworking
          </li>
          <li style={{ listStyle: "disc" }}>
            Integration testing with Devices / Core / OSS-BSS
          </li>
          <li style={{ listStyle: "disc" }}>
            Integration of 3rd Party RAN, Devices
          </li>
          <li style={{ listStyle: "disc" }}>
            Feature Enhancements and Validation
          </li>
          <li style={{ listStyle: "disc" }}>Log Analysis and Bug Fixing</li>
        </ul>

        <h2 className="text-center" style={{ color: "#5C3C9C" }}>
          TESTING
        </h2>
        <h4 style={{ color: "#5C3C9C" }}>
          <b>RAN TESTING</b>
        </h4>
        <ul style={{ margin: "30px" }}>
          <li align="justify" style={{ listStyle: "disc" }}>
            Radio access network (RAN) is defined as the combination of
            telecommunication network elements used to establish communication
            between user equipment and the core network. Traditionally, this has
            included base station components, radios, and antennas that provide
            cell coverage within a specific area. With the advent of 5G, RAN
            architecture has been transformed by network function virtualization
            (NFV) as more intelligence moves from the core to the edge
          </li>
          <li align="justify" style={{ listStyle: "disc" }}>
            Radio Access Networks (RANs) leverage many new network connection
            protocols including heterogeneous networks (HetNets) to make the
            wireless connection for a wider range of services. New devices,
            network configurations, and services require proactive validation
            against realistic live-network scenarios at scale and under
            high-stress conditions.{" "}
          </li>
          <li align="justify" style={{ listStyle: "disc" }}>
            Network connections must be subjected to the widest array of mobile
            applications and subscriber behaviour in the lab to validate live
            network scale and complexity. Fubeus has its expert team working on
            RAN testing providing various 5G RAN testing capabilities in the lab
            as well as in the field
          </li>
        </ul>
        <div className="row mtn-35">
          {ranTestingCard.map((item) => (
            <CardItem
              key={item.id}
              id={item.id}
              title={item.title}
              excerpt={item.excerpt}
              thumb={item.thumb}
              height={item.height}
              width={item.width}
              readmore={item.readmore}
              cols="col-md-6 col-lg-3"
            />
          ))}
        </div>
        <br />
        <br />
        <h4 style={{ color: "#5C3C9C" }}>
          <b>MODEM TESTING</b>
        </h4>
        <span align="justify">
          Test categories carried out by Fubeus for UE Testing is diverse, we
          have proven our ability to match the current market testing needs by
          providing UE test services to major global organizations such as
          Google, Foxconn, Fibocom Sonim, Motorola, Paramore and many more
          different OEMs for conformance testing.
        </span>
        <br />
        <br />
        <h4 style={{ color: "#5C3C9C" }}>
          <b>CONFORMANCE APPROVAL TESTING</b>
        </h4>
        <span>
          Conformance approval is one of the most important steps to bringing
          mobile devices to the market. Fubeus has proven its abilities in the
          conformance approval testing and with the introduction of 5G NR, We
          have encountered exponential growth in the sheer number of test cases
          due to the huge number of supported band combinations, deployment
          options and evolving standards. We follow the certification criteria
          defined by certification bodies such as GCF, PTCRB and CTIA based on
          the 3GPP technical specification (TS 38.521) that comprise four test
          areas: radio frequency conformance (RF), radio resource management
          conformance (RRM), protocol conformance (PC) and performance quality
          analysis (PQA).
        </span>
        <br />
        <br />
        <h4 style={{ color: "#5C3C9C" }}>
          <b>OPERATOR SPECIFIC ACCEPTANCE TESTING</b>
        </h4>
        <span>
          Fubeus provides operator specific acceptance testing as major 5G
          operators ask for additional carrier acceptance testing of devices in
          order to ensure that they properly work in the network and meet
          quality, performance and functionality expectations. Tests carried out
          by Fubeus include enhanced RF, RRM, data throughput (PQA), protocol
          and performance tests for verifying IMS applications, emergency call
          systems, location based services, power consumption and security. We
          have a good hands on experience working with various network
          simulators.
        </span>
        <br />
        <br />
        <div className="row mtn-35">
          {modemRanCard.map((item) => (
            <CardItem
              key={item.id}
              id={item.id}
              title={item.title}
              excerpt={item.excerpt}
              thumb={item.thumb}
              height={item.height}
              s
              width={item.width}
            />
          ))}
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default ContentType01;
