import React, {useState} from 'react';
import {Link} from "react-router-dom";
import parse from "html-react-parser";
import Thumbnail from "../UI/Thumbnail";
import Content from "../UI/Content";
import StorageData from '../../data/Competencies/storage.json'
import DetailsType01 from '../Common/DetailsType01';

const Storage = () => {

    const [data] = useState(StorageData);

    return (
        <div className="about-area-wrapper sm-top">
            <div className="container"> 
                <DetailsType01 img ={data.img} title = {data.title} description ={data.text}/>
            </div>
        </div>
    );
};

export default Storage;