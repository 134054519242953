import React,{Fragment} from 'react';
import Header from "../components/Header";
import ServiceDetails from "../templates/ServiceDetails";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import ServiceType3 from "../components/Services/services03";

const PageServiceDetails = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/about-us-header.jpg')}
                title="Semiconductor"
                content=""
            />
            <ServiceType3/>
            <Footer/>
        </Fragment>
    );
};

export default PageServiceDetails;