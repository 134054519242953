import React, { useState } from "react";
import AutomotiveData from "../../data/Competencies/automotice.json";
import AutomotiveCard1 from "../../data/Competencies/automotiveCard1.json";
import AutomotiveCard2 from "../../data/Competencies/automotiveCard2.json";
import AutomotiveCard3 from "../../data/Competencies/automotiveCard3.json";
import DetailsType01 from "../Common/DetailsType01";
import DetailsType02 from "../Common/DetailsType02";
import CardItem from "../Common/CardItem";

const Auromotive = () => {
  const [data] = useState(AutomotiveData);

  return (
    <div className="about-area-wrapper sm-top">
      <div className="container">
        <p>
          Fubeus’ automotive software development group that helps
          manufacturers, dealerships to automate operations, better engage with
          customers, streamline communication, and enhance driving experience
          with connected car technologies.
        </p>
        <br />
        <h3 className="text-center" style={{ color: "#5C3C9C" }}>
          <b>DEALERSHIPS MANAGEMENT SYSTEM</b>
        </h3>
        <DetailsType01
          img={data[0].img}
          title={data[0].title}
          description={data[0].text}
        />
        <div className="row mtn-35">
          {AutomotiveCard1.map((item) => (
            <CardItem
              key={item.id}
              id={item.id}
              title={item.title}
              excerpt={item.excerpt}
              thumb={item.thumb}
              height="300px"
              width={item.width}
              readmore="false"
            />
          ))}
        </div>
        <br />
        <h3 className="text-center" style={{ color: "#5C3C9C" }}>
          <b>AUTOMOTIVE ECOMMERCE SOLUTIONS</b>
        </h3>
        <br />
        <DetailsType02
          img={data[1].img}
          title={data[1].title}
          description={data[1].text}
        />
        <DetailsType01
          img={data[2].img}
          title={data[2].title}
          description={data[2].text}
        />
        <h3 className="text-center" style={{ color: "#5C3C9C" }}>
          <b>CONNECTED VEHICLE AND CLOUD DATA</b>
        </h3>
        <br />
        <DetailsType01
          img={data[3].img}
          title={data[3].title}
          description={data[3].text}
        />
        <div className="row mtn-35">
          {AutomotiveCard2.map((item) => (
            <CardItem
              key={item.id}
              id={item.id}
              title={item.title}
              excerpt={item.excerpt}
              thumb={item.thumb}
              height={item.height}
              width={item.width}
              style={{ backgroundColor: "#d2cce4" }}
            />
          ))}
        </div>
        <br />
        <br />
        <h3 className="text-center" style={{ color: "#5C3C9C" }}>
          <b>Autonomous & ADAS development Services</b>
        </h3>
        <p>
          Fubeus takes every opportunity to automate, adapt, and enhance vehicle
          systems for safer and more comfortable driving. To do so, we draw on
          years of experience in digital mapping, location-based services,
          predictive road-data management, and ADAS software development. Your
          ADAS software will get information from the map, GPS, sensors of other
          cars ahead, and the cloud to guarantee a fast response to the
          environment for the safest end-to-end solution.
        </p>
        <div
          className="d-flex"
          style={{ alignItem: "center", justifyContent: "center" }}
        >
          <img
            classes="about-thumb"
            src={require("../../assets/img/competencies/automotive/05.png")}
            style={{ height: "420px", width: "760px" }}
          ></img>
        </div>{" "}
        <br />
        <div className="row mtn-35">
          {AutomotiveCard3.map((item) => (
            <CardItem
              key={item.id}
              id={item.id}
              title={item.title}
              excerpt={item.excerpt}
              thumb={item.thumb}
              height={item.height}
              width={item.width}
              style={{ height: "250px", backgroundColor: "#d2cce4" }}
            />
          ))}
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Auromotive;
