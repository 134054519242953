import React, { Fragment } from "react";
import Header from "../components/Header";
import ServiceDetails from "../templates/ServiceDetails";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import ServiceType1 from "../components/Services/services01";
import applicationSoftwareData from "../data/ServicePages/applicationSoftware.json";

const PageServiceDetails = () => {
  const intro =
    "We show our virtuosity in developing custom software that meet the specific business requirements and financial parameters set by our clients. We take up projects in web and mobile applications development. We have delivered multiple web application projects to our customers suing our expertise in Java, J2EE, PHP, C# .NET using various frameworks such Spring, Spring boot, Hibernate. We have teams who have strong front development expertise in Angular, React and Node.js.";
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/about-us-header.jpg")}
        title="Application Software"
        content=""
      />
      <ServiceType1 data={applicationSoftwareData} intro={intro} />
      <Footer />
    </Fragment>
  );
};

export default PageServiceDetails;
