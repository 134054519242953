import React, { useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Thumbnail from "../UI/Thumbnail";
import Content from "../UI/Content";
import HealthData from "../../data/Competencies/health.json";
import DigitalPatientData from "../../data/Competencies/digitalPatientData.json";
import MobileHealth from "../../data/Competencies/mobileHealth.json";
import DetailsType01 from "../Common/DetailsType01";
import DetailsType02 from "../Common/DetailsType02";
import CardItem from "../Common/CardItem";
import SectionTitle from "../UI/SectionTitle";

const Health = () => {
  const [data] = useState(HealthData);

  return (
    <div className="about-area-wrapper sm-top">
      <div className="container">
        <p>
          Fubeus delivers technological solutions and services for healthcare
          organizations and practitioners, assisted living facilities, nursing
          homes, diagnostic and research centers, medical ISVs, and device
          manufacturers. Fubeus believes that the custom healthcare software
          development is the key enabler of healthcare interoperability, patient
          engagement, accurate diagnostics, and positive healthcare outcomes.
        </p>
        <DetailsType01
          img={data[0].img}
          title={data[0].title}
          description={data[0].text}
        />
        <h3 className="text-center" style={{ color: "#5C3C9C" }}>
          <b>HEALTHCARE SOFTWARE INTEGRATION</b>
        </h3>
        <p>
          Fubeus helps our healthcare clients create connected technological
          ecosystems. We integrate custom and platform-based apps with EHR,
          PACS, HIE, CRM, claim management systems, mobile medical apps, and
          other solutions in use by providers.
        </p>
        <div
          className="d-flex"
          style={{ alignItem: "center", justifyContent: "center" }}
        >
          <img
            classes="about-thumb"
            src={require("../../assets/img/competencies/details/health2.png")}
            style={{ height: "420px", width: "760px" }}
          ></img>
        </div>{" "}
        <br />
        <DetailsType02
          img={data[1].img}
          title={data[1].title}
          description={data[1].text}
        />
        <h3 className="text-center" style={{ color: "#5C3C9C" }}>
          <b>DIGITAL PATIENT EXPERIENCE</b>
        </h3>
        <br />
        <div className="row mtn-35">
          {DigitalPatientData.map((item) => (
            <CardItem
              key={item.id}
              id={item.id}
              title={item.title}
              excerpt={item.excerpt}
              thumb={item.thumb}
              height={item.height}
              width={item.width}
              readmore="false"
            />
          ))}
        </div>
        <br />
        <br />
        <h3 className="text-center" style={{ color: "#5C3C9C" }}>
          <b>INNOVATIVE MEDICAL SYSTEM DESIGN AND SOFTWARE</b>
        </h3>
        <div className="team-area bg-brand">
          <div className="row no-gutters align-items-center w-100">
            <div className="row">
              <div className="col-xl-6">
                <div
                  className="team-area-left text-center text-md-left"
                  style={{ padding: "53px 25px" }}
                >
                  <SectionTitle
                    variant="light"
                    title="VITAL PATIENT MONITORING SYSTEMS"
                    heading=""
                    text="Fubeus’ team is involved in various Medical devices system
                  design from tiny hardware design to complete system
                  integration, Embedded Software and application software to
                  support those devices to monitor patients vital parameters
                  such as BP, Sugar, Oxygen Level and transmit the data to
                  concerned healthcare systems."
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div
                  className="team-area-left text-center text-md-left"
                  style={{ padding: "53px 25px" }}
                >
                  <SectionTitle
                    variant="light"
                    title="MEDICAL IMAGE ANALYSIS"
                    heading=""
                    text="Fubeus creates medical imaging software for research and clinical needs. Our solutions support different medical image modalities, raise the effectiveness of early diagnostics, and reduce the risk of medical errors and excessive tests. When adopted for educational purposes, our software helps clinicians to explore new domains faster and increase precision of medical analysis and prescriptions."
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6">
                <div
                  className="team-area-left text-center text-md-left"
                  style={{ padding: "53px 25px" }}
                >
                  <SectionTitle
                    variant="light"
                    title="TELEMEDICINE"
                    heading=""
                    text="Fubeus delivers telemedicine solutions that digitally connect patients to healthcare experts in order to provide remote diagnostics, post-discharge treatment, chronic disease management, and preventive care. IoMT solutions also make part of this service pack. With them, we aim at facilitating self-managed and supervised treatment of chronic and acute health conditions, along with increasing patient engagement and loyalty."
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div
                  className="team-area-left text-center text-md-left"
                  style={{ padding: "53px 25px" }}
                >
                  <Thumbnail
                    classes="about-thumb"
                    imgSrc={require("../../assets/img/competencies/details/health10.png")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <h3 className="text-center" style={{ color: "#5C3C9C" }}>
          <b>MOBILE HEALTH ON THE GO</b>
        </h3>
        <br />
        <p>
          Fubeus provides healthcare mobile app development services to extend
          the capabilities of medical software to include mobile devices. We
          develop native, cross-platform and hybrid apps as both standalone
          mobile care solutions or parts of software suites.
        </p>
        <br />
        <div className="row mtn-35">
          {MobileHealth.map((item) => (
            <CardItem
              key={item.id}
              id={item.id}
              title={item.title}
              excerpt={item.excerpt}
              thumb={item.thumb}
              height={item.height}
              width={item.width}
              readmore="false"
            />
          ))}
        </div>
        <DetailsType02
          img={data[2].img}
          title={data[2].title}
          description={data[2].text}
          description2={data[2].text2}
        />
      </div>
    </div>
  );
};

export default Health;
