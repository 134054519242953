import React from "react";

const NodeDevelopemntContentWrapper = ({ post, sidebar }) => {
  return (
    <article className="blog-post-details">
      <div className="blog-post-txt-wrap">
        <div className="row">
          {!sidebar ? (
            <div className="col-md-3 col-lg-2 order-1 order-md-0">
              <div className="author-info mt-sm-40">
                <div className="author-thumb">
                  <img
                    src={require("../../assets/img/" + post.author.proPic)}
                    alt={post.author.name}
                  />
                </div>
                <div className="author-txt">
                  <h5>
                    {post.author.name}{" "}
                    <span className="designation">
                      {post.author.designation}
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          ) : null}

          <div
            className={`m-auto order-0 ${
              !sidebar ? "col-md-9 col-lg-8" : "col-12"
            }`}
          >
            <div className="blog-post-txt">
              <h3 className="text-center">
                <strong>{post.title}</strong>
              </h3>
              <br />
              <div
                className="d-flex"
                style={{ alignItem: "center", justifyContent: "center" }}
              >
                <img
                  classes="about-thumb"
                  src={require("../../assets/img/blog/16.jpg")}
                  style={{ height: "296px", width: "646px" }}
                  alt="blog"
                ></img>
              </div>{" "}
              <br />
              <ul style={{ paddingLeft: "10px" }}>
                <li style={{ listStyle: "disc" }}>
                  In 1st generation it was known as Base Station (BS)
                </li>
                <li style={{ listStyle: "disc" }}>
                  2G changed name with Base Transceiver Station (BTS)
                </li>
                <li style={{ listStyle: "disc" }}>3G remaned it to NodeB</li>
                <li style={{ listStyle: "disc" }}>
                  In 4G the name is changed to eNodeB as it was an “evolution”
                  of 3G, so an it becomes “evolved” NodeB
                </li>
                <li style={{ listStyle: "disc" }}>
                  Finally in 5G we got next generation NodeB, so given name as
                  gNB
                </li>
              </ul>
              <div
                className="d-flex"
                style={{ alignItem: "center", justifyContent: "center" }}
              >
                <img
                  classes="about-thumb"
                  src={require("../../assets/img/blog/17.jpg")}
                  style={{ height: "362px", width: "633px" }}
                ></img>
              </div>{" "}
              <br />
              <ul style={{ paddingLeft: "10px" }}>
                <li style={{ listStyle: "disc" }}>
                  eNB is a 4G base station which is connected to EPC (Evolved
                  packet core).
                </li>
                <li style={{ listStyle: "disc" }}>
                  eNB is connected to EPC via S1 interface.
                </li>
                <li style={{ listStyle: "disc" }}>
                  The interface between eNB and MME is S1-C interface, which is
                  typically used for signalling message.
                </li>
                <li style={{ listStyle: "disc" }}>
                  SGW is connected to eNB via S1-U interface, which is data
                  path.
                </li>
                <li>
                  En-gNB refers to (en-gNB EUTRA New Radio dual connectivity
                  gNB), which is basically a 5G base station , which will be
                  connected to 4G core for providing dual connectivity.
                </li>
              </ul>
              <div
                className="d-flex"
                style={{ alignItem: "center", justifyContent: "center" }}
              >
                <img
                  classes="about-thumb"
                  src={require("../../assets/img/blog/18.jpg")}
                  style={{ height: "346px", width: "609px" }}
                ></img>
              </div>{" "}
              <br />
              <ul style={{ paddingLeft: "10px" }}>
                <li style={{ listStyle: "disc" }}>
                  eNB is a 4G base station which is connected to EPC (Evolved
                  packet core).
                </li>
                <li style={{ listStyle: "disc" }}>
                  eNB is connected to EPC via S1 interface.
                </li>
                <li style={{ listStyle: "disc" }}>
                  The interface between eNB and MME is S1-C interface, which is
                  typically used for signalling message.
                </li>
                <li style={{ listStyle: "disc" }}>
                  SGW is connected to eNB via S1-U interface, which is data
                  path.
                </li>
                <li style={{ listStyle: "disc" }}>
                  En-gNB refers to (en-gNB EUTRA New Radio dual connectivity
                  gNB), which is basically a 5G base station , which will be
                  connected to 4G core for providing dual connectivity.
                </li>
              </ul>
            </div>

            <div className="share-article text-center">
              <h6>Share this article</h6>
              <div className="member-social-icons justify-content-center">
                <a
                  className="facebook"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                >
                  <i className="fa fa-facebook" />
                </a>
                <a
                  className="twitter"
                  href={`https://twitter.com/home?status=${window.location.href}`}
                >
                  <i className="fa fa-x-twitter" />
                </a>
                <a
                  className="reddit"
                  href={`http://reddit.com/submit?url=${window.location.href}&title=${post.title}`}
                >
                  <i className="fa fa-reddit" />
                </a>
                <a
                  className="pinterest"
                  href={`https://pinterest.com/pin/create/button/?url=${window.location.href}&media=&description=`}
                >
                  <i className="fa fa-pinterest" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default NodeDevelopemntContentWrapper;
