import React from "react";
import NavbarNew from "./Navnew";

function Navbar(props) {
  return (
    <div>
      {/* <ul className="main-menu nav" style={{marginLeft:"50px"}}> */}
      {/* <NavbarItem /> */}
      <NavbarNew />
      {/* <NavbarNew/> */}
      {/* </ul> */}
      {/* <Link  className='btn btn-brand contact' style={{alignItem : "center" , justifyContent : "center"}} to="/contact">CONTACT US</Link> */}
      {/* <button style={{backgroundColor: '#5C3C9C'}} onClick={contactUsFun}>CONTACT US</button> */}
    </div>
  );
}

export default Navbar;
