import React, { Fragment } from "react";
import Header from "../components/Header";
import ServiceDetails from "../templates/ServiceDetails";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import ServiceType1 from "../components/Services/services01";
import cloudDataAnalytics from "../data/ServicePages/couldDataAnalytics.json";

const PageServiceDetails = () => {
  const intro =
    "Fubeus’ Cloud engineering competency across a wide gamut of proven cloud technologies and native engineering services enable you to design, develop, integrate, and launch effective applications. We offer a comprehensive suite of services that encompasses all aspects of the entire software development life cycle in the cloud, including consultation, systems integration, architecture and design, development, testing and deployment.";
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/about-us-header.jpg")}
        title="Cloud and Data Analytics"
        content=""
      />
      <ServiceType1 data={cloudDataAnalytics} intro={intro} />
      <Footer />
    </Fragment>
  );
};

export default PageServiceDetails;
