import React from "react";
import CardItem from "../Common/CardItem";

import Items from "../../data/Products/fboxcard1.json";

function ProductCard() {
  return (
    <div className="blog-area-wrapper sm-top">
      <div className="container">
        <div className="row mtn-35">
          {Items.map((item) => (
            <CardItem
              key={item.id}
              id={item.id}
              title={item.title}
              excerpt={item.excerpt}
              thumb={item.thumb}
              height={item.height}
              width={item.width}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
