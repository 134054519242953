import NavbarItem from "./NavbarItem";
import Logo from "../Logo";
import { Link } from "react-router-dom";
const NavbarNew = () => {
  return (
    //     <nav class="navbar navbar-expand-lg bg-body-tertiary">
    //   <div class="container-fluid">
    //     <a class="navbar-brand" href="#">Navbar</a>
    //     <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    //       <span class="navbar-toggler-icon"></span>
    //     </button>
    //     <div class="collapse navbar-collapse" id="navbarNavDropdown">
    //       <ul class="navbar-nav">
    //         <li class="nav-item">
    //           <a class="nav-link active" aria-current="page" href="#">Home</a>
    //         </li>
    //         <li class="nav-item">
    //           <a class="nav-link" href="#">Features</a>
    //         </li>
    //         <li class="nav-item">
    //           <a class="nav-link" href="#">Pricing</a>
    //         </li>
    //         <li class="nav-item dropdown">
    //           <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    //             Dropdown link
    //           </a>
    //           <ul class="dropdown-menu">
    //             <li><a class="dropdown-item" href="#">Action</a></li>
    //             <li><a class="dropdown-item" href="#">Another action</a></li>
    //             <li class="dropdown-item dropdown">
    //                 <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    //                     something here
    //                 </a>
    //                     <ul class="dropdown-menu">
    //                         <li><a class="dropdown-item " href="#">Action</a></li>
    //                         <li><a class="dropdown-item" href="#">Another action</a></li>
    //                     </ul>
    //             </li>
    //           </ul>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    // </nav>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <Logo />
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item active ">
            <a class="nav-link" href="/">
              <b>Home</b> <span class="sr-only">(current)</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/about">
              <b>About Us</b>
            </a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <b>Product</b>{" "}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li>
                <a class="dropdown-item" href="/fbox">
                  FBoX
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/android-dvb-stack">
                  Android DVB Stack
                </a>
              </li>
              {/* <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="http://google.com">Google</a>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#">Submenu</a></li>
                            <li><a class="dropdown-item" href="#">Submenu0</a></li>
                            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#">Submenu 1</a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Subsubmenu1</a></li>
                                    <li><a class="dropdown-item" href="#">Subsubmenu1</a></li>
                                </ul>
                            </li>
                            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#">Submenu 2</a>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Subsubmenu2</a></li>
                                    <li><a class="dropdown-item" href="#">Subsubmenu2</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li> */}
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <b>Services</b>{" "}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li>
                <a class="dropdown-item" href="/application-software">
                  Application Software
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/cloud-and-data-analytics">
                  Cloud and Data Analytics
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/devops">
                  DevOps
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/semiconductor">
                  Semiconductor
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/embedded-systems">
                  Embedded Systems
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/quality-analysis">
                  Quality Analysis and Automation
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {" "}
              <b>Competencies</b>{" "}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li>
                <a class="dropdown-item" href="/telecommunication">
                  Telecommunication
                </a>
              </li>
              <li class="dropdown-submenu">
                <a class="dropdown-item dropdown-toggle">4G/5G</a>
                <ul class="dropdown-menu">
                  <li>
                    <a class="dropdown-item" href="/5g-ran">
                      5G Modem/RAN
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/5g-core">
                      5G Core
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/private-5g">
                      Private 5G Network
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/5g-lab">
                      5G Lab Setup
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/vran-oran">
                      VRAN/ORAN Services
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="/network-cloud-orchestration"
                    >
                      Network Cloud and Orchestration
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a class="dropdown-item" href="/storage">
                  Storage
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/iot">
                  IoT
                </a>
              </li>
              <li class="dropdown-submenu">
                <a class="dropdown-item dropdown-toggle">
                  Digital Communication
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a class="dropdown-item" href="/retail">
                      Retail
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/fintech">
                      Fintech
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a class="dropdown-item" href="/health-care">
                  Healthcare
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/automotive">
                  Automotive
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {" "}
              <b>People</b>{" "}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li>
                <a class="dropdown-item" href="/blogs">
                  Blogs
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/life-at-fubeus">
                  Life At Fubeus
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/career">
                  Careers
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <Link className="btn btn-brand ms-5" to="/contact">
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavbarNew;
