import React, { useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Thumbnail from "../UI/Thumbnail";
import Content from "../UI/Content";
import embeddedSystemsData from "../../data/ServicePages/embeddedSystems.json";
import DetailsType01 from "../Common/DetailsType01";
import DetailsType02 from "../Common/DetailsType02";

const EmbeddedSystems = () => {
  const [data] = useState(embeddedSystemsData);

  return (
    <div className="about-area-wrapper sm-top">
      <div className="container">
        <h2 className="text-center" style={{ color: "#5C3C9C" }}>
          EMBEDDED SYSTEMS DESIGN
        </h2>
        <span align="justify">
          Fubeus Embedded Systems Design Group (ESG) closely works with
          customers to provide quicker time-to-market, reliable and
          cost-effective solutions. ESG handholds its customers from proof of
          concept to pilot production to mass production and partners with
          customers for after sales support.
          <br />
          <br />
        </span>
        <DetailsType01
          img={data[0].thumb}
          title={data[0].title}
          description={data[0].text}
        />
        <p align="justify">
          We have strategic alliance partnerships and considerable experiences
          with major semiconductor vendors like Intel, AMD, NXP, Toshiba,
          Western Digital, Dell Broadcom, Silicon Labs, etc. Our team of expert
          engineers hold in-depth knowledge in working with various bare metal
          and operating system-based designs. With deep domain expertise and
          process-oriented approach, we have developed various system design
          projects for IoT, automotive, industrial automation, defense, consumer
          electronics, healthcare, and other high-tech industries.
        </p>
        <br />
        <br />
        <h3 className="text-center" style={{ color: "#5C3C9C" }}>
          <b>EMBEDDED SOFTWARE SERVICES</b>
        </h3>
        <DetailsType02
          img={data[1].thumb}
          title={data[1].title}
          description={data[1].text}
        />
        <br />

        <div>
          <h3 style={{ color: "#5C3C9C" }}>
            <b>EXPERTISE IN EMBEDDED OS AND BSP DEVELOPMENT</b>
          </h3>
          <p align="justify">
            At Fubeus, we offer BSP Development services that ease porting of
            embedded operating systems for custom hardware designs. Fubeus has
            helped device manufacturers start from choosing the right OS for
            their hardware, bringing up the system in the shortest possible
            time, and running applications on it. Fubeus has expertise in
            working with various OS like Android, Linux, WinCE, μClinux, QNX,
            μCOS, FreeRTOS, MQX, etc.
            <br />
            <br />
            Fubeus’ team has been in the forefront to adapt any new
            updates/releases of the OS quickly and early into the custom boards.
            We have ported popular operating systems such as WinCE, Android and
            Linux, more frequently, primarily on to various ARM processors as
            well as on X-86 platforms.
          </p>
          <ul>
            <li style={{ listStyle: "disc" }}>
              Windows Embedded (from WinCE 4.0 to the Windows Embedded Compact
              2013)
            </li>
            <li style={{ listStyle: "disc" }}>
              Embedded Linux (from the early 2.4 to the latest kernels)
            </li>
            <li style={{ listStyle: "disc" }}>
              Android (from 2.3 to latest versions)
            </li>
            <li style={{ listStyle: "disc" }}>
              FreeRTOS – To major architectures.
            </li>
          </ul>{" "}
          <br />
          <br />
        </div>
        <h2 className="text-center" style={{ color: "#5C3C9C" }}>
          ESG SPECIALISATIONS
        </h2>
        <DetailsType01
          img={data[2].thumb}
          title={data[2].title}
          description={data[2].text}
        />
        <DetailsType02
          img={data[3].thumb}
          title={data[3].title}
          description={data[3].text}
        />
        <DetailsType01
          img={data[4].thumb}
          title={data[4].title}
          description={data[4].text}
        />
        <br />
        <div>
          <h2 className="text-center" style={{ color: "#5C3C9C" }}>
            HIGH SPEED INTERFACES
          </h2>
          <div
            className="d-flex"
            style={{ alignItem: "center", justifyContent: "center" }}
          >
            <Thumbnail
              classes="about-thumb"
              imgSrc={require("../../assets/img/service/details/16.png")}
            />
          </div>
        </div>
        <div>
          <h3 className="text-center" style={{ color: "#5C3C9C" }}>
            <b>PLATFORMS</b>
          </h3>
          <p align="justify">
            Our team has a good hands on experience on the embedded platforms
            such as Xilinx Virtex7-2000t Development board, Zynq board, Zynq7000
            processor, NXP 2160 and LA1224 , Microsemi FPGA, Intel FPGA , ARM,
            Freescale, NXP, TI, Samsung, Qualcomm, Microchip and many more.
            <br />
            <br />
          </p>
          <p align="justify">
            We offer a powerful set of middleware components for multimedia
            applications. These include display, video, audio and image
            processing technologies, security and connectivity components, as
            well as a rich selection of APIs for manipulating and generating
            content. We support all major{" "}
            <b>operating systems (Windows, Linux and Mac)</b> on hardware from
            leading manufacturers such as NVIDIA, Broadcom and Atheros.
            <br />
            <br />
          </p>
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-5">
              <Thumbnail
                classes="about-thumb"
                imgSrc={require("../../assets/img/service/details/embedded.png")}
              />
            </div>
            <div className="col-md-6 col-lg-5">
              <Thumbnail
                classes="about-thumb"
                imgSrc={require("../../assets/img/service/details/17.png")}
              />
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default EmbeddedSystems;
