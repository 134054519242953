import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

/*
 * @ All pages Import
 */
import HomeOne from "./pages/HomeOne";
import Error404 from "./pages/Error404";
import ScrollToTop from "./helpers/ScrollToTop";
import FourGFiveG from "./pages/4g5g";
import FiveGModemRan from "./pages/5gModemRan";
import FiveGCore from "./pages/5gCore";
import FiveGLab from "./pages/5gLab";
import FivegRanArchitecture from "./pages/5gRanArchitecture";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import AndroidStack from "./pages/AndroidStack";
import ApplicationSoftware from "./pages/ApplicationSoftware";
import Career from "./pages/Career";
import CloudDataAnalytics from "./pages/CloudDataAnalytics";
import PageAbout from "./pages/About";
import EmbeddedSystems from "./pages/EmbeddedSystems";
import Fbox from "./pages/Fbox";
import NetworkCloud from "./pages/NetworkCloud";
import Iot from "./pages/Iot";
import Retail from "./pages/Retail";
import NodeBDevelopment from "./pages/NodeBDevelopment";
import Automotive from "./pages/Automotive";
import TeleCommunication from "./pages/TeleCommunication";
import PrivateFiveG from "./pages/Private5g";
import Vran from "./pages/VirtualService";
import LifeAtFuebus from "./pages/LifeAtFuebus";
import HealthCare from "./pages/HealthCare";
import Devops from "./pages/Devops";
import Semiconductor from "./pages/Semiconductor";
import QualityAnalysis from "./pages/QualityAnalysis";
import DualConnectivityDetails from "./pages/DualConnectivity";
import Storage from "./pages/Storage";
import Fintech from "./pages/Fintech";

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/about"}`}
            component={PageAbout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/application-software"}`}
            component={ApplicationSoftware}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/android-dvb-stack"}`}
            component={AndroidStack}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/4g-5g"}`}
            component={FourGFiveG}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/5g-ran"}`}
            component={FiveGModemRan}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/career"}`}
            component={Career}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/cloud-and-data-analytics"}`}
            component={CloudDataAnalytics}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={HomeOne}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/5g-core"}`}
            component={FiveGCore}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/5g-lab"}`}
            component={FiveGLab}
          />

          <Route
            path={`${process.env.PUBLIC_URL + "/blog/5g-ran-architecture"}`}
            component={FivegRanArchitecture}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/blogs"}`}
            component={Blogs}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/embedded-systems"}`}
            component={EmbeddedSystems}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/fbox"}`}
            component={Fbox}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/network-cloud-orchestration"}`}
            component={NetworkCloud}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/iot"}`}
            component={Iot}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/retail"}`}
            component={Retail}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/blog/node-b-evolution"}`}
            component={NodeBDevelopment}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/automotive"}`}
            component={Automotive}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/telecommunication/"}`}
            component={TeleCommunication}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/private-5g"}`}
            component={PrivateFiveG}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/vran-oran"}`}
            component={Vran}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/life-at-fubeus"}`}
            component={LifeAtFuebus}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/health-care"}`}
            component={HealthCare}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/devops"}`}
            component={Devops}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/semiconductor"}`}
            component={Semiconductor}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/quality-analysis"}`}
            component={QualityAnalysis}
          />
          <Route
            path={`${
              process.env.PUBLIC_URL + "/blog/multi-rat-dual-connectivity"
            }`}
            component={DualConnectivityDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/storage"}`}
            component={Storage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/fintech"}`}
            component={Fintech}
          />

          <Route exact component={Error404} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default App;
