import React from "react";
import coreCard from "../../../data/Competencies/5g/core.json";
import CardItem from "../../Common/CardItem";

const ContentType02 = () => {
  return (
    <div className="about-area-wrapper sm-top">
      <div className="container">
        <div
          className="d-flex"
          style={{ alignItem: "center", justifyContent: "center" }}
        >
          <img
            classes="about-thumb"
            src={require("../../../assets/img/competencies/5g/02.jpg")}
            width="850"
            height="350"
          />
        </div>
        <br />
        <br />
        <h3 style={{ color: "#5C3C9C" }}>4G / 5G </h3>
        <span align="justify">
          Our team of engineers is unique in the industry with extensive
          experience from the key stack development teams at major telecom
          software companies. They played key roles in innovating, developing,
          implementing and testing L1, L2, L3 layer for 802.11a/b/g/n/ac/ad,
          IPv4, IPv6, OSPF, RIP, STP VLANs and so on. <br />
          <br />
        </span>
        <span align="justify">
          Fubeus is one of the leading service companies in the world in terms
          of development and testing of 4G/5G software across L2 and L3. Fubeus
          has been there since the start of 5G NR technology, and gathered
          experience in development and testing of 5G all around the globe in
          several countries (namely USA, India, UK, Europe).
        </span>
        <br />
        <br />
        <span align="justify">
          Fubeus is well equipped at all major continents and cities to carry
          out Development and Testing of 5G RAN and UE Protocol stack with
          respect to 5G SA and NSA architecture. Fubeus is capable of carrying
          out lab tests, drive tests, Field tests, E2E call flow test and
          automation test of 5G SA and NSA technology. Our team of experienced
          project managers, and engineers are always up to date and capable of
          executing and servicing of 5G projects.
        </span>
        <br />
        <br />
        <span align="justify">
          We have an expertise in working of on call and packet processing, OAM,
          protocol stacks, BSP, hardware and OS abstraction, network simulators,
          traffic generators, and RF tools across the entire product lifecycle.
        </span>
        <br />
        <br />
        <span align="justify">
          Fubeus offers services in turnkey mode or even can take up the
          ownership of projects. Our solutions and services include design,
          development, integration, testing and maintenance of your stack for
          UE, RAN or EPC for 4G/5G.
        </span>
        <br />
        <br />

        <h3 style={{ color: "#5C3C9C" }}>4G / 5G Core Network Services</h3>
        <span align="justify">
          The core network forms an essential part of the mobile
          telecommunications system as it aggregates incoming data traffic from
          end devices. It also authenticates subscribers and devices, applies
          personalized policies and manages the mobility of the devices before
          routing the traffic to operator services or the Internet. As the data
          traffic is bound to increase multifold to gratify the upcoming user
          demands, we are going to witness a core network transformation from 4G
          EPC to 5G next generation core while operators employ the concepts of
          CUPS, SDN/NFV & more.
          <br />
          <br />
        </span>
        <span align="justify">
          Fubeus Core Network services portfolio intends to provide the ideal
          solutions to the core network challenges throughout the network
          lifecycle that will not only drastically cut-down capital &
          operational expenditure for our customers but will also introduce
          agility in their systems and enable new revenue streams.
        </span>
        <br />
        <br />
        <br />
        <div className="row mtn-35">
          {coreCard.map((item) => (
            <CardItem
              key={item.id}
              id={item.id}
              title={item.title}
              excerpt={item.excerpt}
              thumb={item.thumb}
              height={item.height}
              width={item.width}
            />
          ))}
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default ContentType02;
