import React, { Fragment } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import ContentType01 from "../components/Competencies/5g/contentType01";

const PageServiceDetails = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/about-us-header.jpg")}
        title="5G Modem/Ran"
        content=""
      />
      <ContentType01 />
      <Footer />
    </Fragment>
  );
};

export default PageServiceDetails;
