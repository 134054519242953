import React,{Fragment} from 'react';
import Header from "../components/Header";
import ContactPage from "../templates/Contact";
import Footer from "../components/Footer";
import Map from "../components/Map"

const PageContact = () => {
    return (
        <Fragment>
            <Header/>
            <ContactPage/>
            <Map/>
            <Footer/>
        </Fragment>
    );
};

export default PageContact;