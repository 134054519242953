import React,{Fragment} from 'react';
import Header from "../components/Header";
import ServiceDetails from "../templates/ServiceDetails";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import ProductType2 from "../components/Products/product02";

const PageServiceDetails = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/about-us-header.jpg')}
                title="Android DVB Stack"
                content=""
            />
            <ProductType2/>
            <Footer/>
        </Fragment>
    );
};

export default PageServiceDetails;