import React, { useState } from "react";
import parse from "html-react-parser";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ReadMore = (props) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <>
      <div
        className="text card-data"
        dangerouslySetInnerHTML={{
          __html:
            (isReadMore && props.text.length) > 200
              ? `${props.text.slice(0, 200)}...`
              : props.text,
        }}
      ></div>
      {props.text.length > 200 && (
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "...read more" : " show less"}
        </span>
      )}
    </>
  );
};

function CardItem(props) {
  return (
    <div className={props.cols ? props.cols : "col-md-6 col-lg-4"}>
      <div
        className={props.class ? props.class : "blog-item"}
        style={props.style2 ? props.style2 : {}}
      >
        {props.thumb ? (
          <div
            className="d-flex"
            style={{ alignItem: "center", justifyContent: "center" }}
          >
            <figure className="blog-thumb">
              <LazyLoadImage
                src={require("../../assets/img/" + props.thumb)}
                alt={props.title}
                width={props.width}
                height={props.height}
                style={props.socialLinks ? { border: "5px solid #d2cce4" } : {}}
                effect="blur"
              />
            </figure>
          </div>
        ) : null}
        {props.socialLinks && (
          <div
            className="member-social-icons mt-7"
            style={{
              alignItem: "center",
              justifyContent: "center",
              marginLeft: "159px",
            }}
          >
            {props.socialLinks.map((social) => (
              <div className="outer-l-logo">
                <div className="l-logo">
                  <a
                    key={social.id}
                    href={`https://linkedin.com/${social.username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#6651c0" }}
                  >
                    <i className={`fa fa-${social.networkName}`} />
                  </a>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="blog-content" style={props.style ? props.style : {}}>
          <h5
            style={{ color: "#5C3C9C" }}
            dangerouslySetInnerHTML={{ __html: props.title }}
          ></h5>
          {props.subtitle && (
            <h8>
              <b>{props.subtitle}</b>
            </h8>
          )}
          {props.readmore && props.readmore === "false" ? (
            <div
              align="justify"
              dangerouslySetInnerHTML={{ __html: props.excerpt }}
            ></div>
          ) : (
            <ReadMore text={props.excerpt}></ReadMore>
          )}
        </div>
      </div>
    </div>
  );
}

export default CardItem;
