import React, { Fragment } from "react";
import Header from "../components/Header";
import ServiceDetails from "../templates/ServiceDetails";
import Footer from "../components/Footer";
import PageHeader from "../components/PageHeader";
import ServiceType4 from "../components/Services/services04";

const PageServiceDetails = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/about-us-header.jpg")}
        title="Embedded Systems"
        content=""
      />
      <ServiceType4 />
      <Footer />
    </Fragment>
  );
};

export default PageServiceDetails;
