import React,{Fragment} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
// import About from "../components/About/home-two";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import Testimonial from "../components/Testimonials/home-one";
import Team from "../components/Team/home-two";
import About from '../components/About/home-one'
import LifeAtFubeusContent from '../components/LifeatFubeus'

import ServiceThumb from '../assets/img/about.jpg'
import TeamMember from '../templates/Team';

const PageAbout = () => {
    return (
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/about-us-header.jpg')}
                title="Life At Fubeus"
                content=""
            />
            <LifeAtFubeusContent/>
            <Testimonial/>
            <Footer/>
        </Fragment>
    );
};

export default PageAbout;