import React from "react";

const NodeDevelopemntContentWrapper = ({ post, sidebar }) => {
  return (
    <article className="blog-post-details">
      <div className="blog-post-txt-wrap">
        <div className="row">
          {!sidebar ? (
            <div className="col-md-3 col-lg-2 order-1 order-md-0">
              <div className="author-info mt-sm-40">
                <div className="author-thumb">
                  <img
                    src={require("../../assets/img/" + post.author.proPic)}
                    alt={post.author.name}
                  />
                </div>
                <div className="author-txt">
                  <h5>
                    {post.author.name}{" "}
                    <span className="designation">
                      {post.author.designation}
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          ) : null}

          <div
            className={`m-auto order-0 ${
              !sidebar ? "col-md-9 col-lg-8" : "col-12"
            }`}
          >
            <div className="blog-post-txt">
              <h3 className="text-center">
                <b>{post.title}</b>
              </h3>
              <p>
                Multi RAT Dual Connectivity (MR-DC) is range of different dual
                connectivity options available in 5G Non standalone architecture
                to provide user plane through put across both 4G and 5G
                air-interface. Legacy 4G UE can use eNodeB in normal way, while
                the new 4G/5G capable UE can take advantage of MR-DC.
              </p>
              <p>
                In MR-DC, UE in connected state can get service from both master
                node and secondary node. Master node provides both control plane
                and user plane connectivity whereas secondary node is used for
                additional data capacity.
              </p>
              <p>
                Majority of RRC signalling procedures terminate at master node.
                Signalling radio bearer 0(SRB0) is configured only for master
                node. Whereas SRB1 and SRB2 can be split across master node and
                secondary node. In case of EN-DC, SRB3 can be configured for
                secondary node, which is used for carrying limited number of
                signaling message for NR i.e. RRC Reconfiguration, RRC
                Reconfiguration Complete and Measurement Report.
              </p>
              <p>
                Secondary node is added either automatically or based on the
                measurement reports of secondary node cells. I.e. if UE camped
                on 4G cells, finds a good power 5G cells and reports it to
                master node, secondary node addition for 5G cell is initiated.
              </p>
              <h3>EN-DC: E-UTRA New Radio Dual Connectivity</h3>
              <p>eNodeB is master node and gNodeB is secondary node.</p>
              <div
                className="d-flex"
                style={{ alignItem: "center", justifyContent: "center" }}
              >
                <img
                  classes="about-thumb"
                  src={require("../../assets/img/blog/19.jpg")}
                  style={{ height: "306px", width: "607px" }}
                  alt="blog"
                ></img>
              </div>{" "}
              <br />
              <p>
                MCG (Master cell group) bearer uses RLC, MAC and physical layers
                with in master node. PDCP layer can be either 4G or 5G.
              </p>
              <p>
                SCG (Secondary cell group) bearer uses RLC, MAC and physical
                bearers with in secondary node. 5G version of PDCP layer is used
                irrespective of master/secondary node.
              </p>
              <p>
                Split bearer uses RLC, MAC and physical layers with in both
                master node and secondary node. 5G version of PDCP layer is
                used.
              </p>
              <div
                className="d-flex"
                style={{ alignItem: "center", justifyContent: "center" }}
              >
                <img
                  classes="about-thumb"
                  src={require("../../assets/img/blog/21.jpg")}
                  style={{ height: "340px", width: "840px" }}
                  alt="img"
                ></img>
              </div>{" "}
              <br />
              <h3>NE-DC: New Radio E-UTRA Dual Connectivity</h3>
              <p>
                gNodeB is master node and next generation eNodeB is secondary
                node.
              </p>
              <div
                className="d-flex"
                style={{ alignItem: "center", justifyContent: "center" }}
              >
                <img
                  classes="about-thumb"
                  src={require("../../assets/img/blog/22.jpg")}
                  style={{ height: "313px", width: "600px" }}
                  alt="img"
                ></img>
              </div>{" "}
              <br />
              <p>
                All user plane bearers connected to 5G core network use service
                data adaption protocol (SDAP) layer. SDAP is located above PDCP
                and is used to map QoS flow on to DRBs.
              </p>
              <p>All user plane bearers use 5G version of PDCP layer.</p>
              <div
                className="d-flex"
                style={{ alignItem: "center", justifyContent: "center" }}
              >
                <img
                  classes="about-thumb"
                  src={require("../../assets/img/blog/20.jpg")}
                  style={{ height: "420px", width: "760px" }}
                  alt="img"
                ></img>
              </div>{" "}
              <br />
            </div>

            <div className="share-article text-center">
              <h6>Share this article</h6>
              <div className="member-social-icons justify-content-center">
                <a
                  className="facebook"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                >
                  <i className="fa fa-facebook" />
                </a>
                <a
                  className="twitter"
                  href={`https://twitter.com/home?status=${window.location.href}`}
                >
                  <i className="fa fa-x-twitter" />
                </a>
                <a
                  className="reddit"
                  href={`http://reddit.com/submit?url=${window.location.href}&title=${post.title}`}
                >
                  <i className="fa fa-reddit" />
                </a>
                <a
                  className="pinterest"
                  href={`https://pinterest.com/pin/create/button/?url=${window.location.href}&media=&description=`}
                >
                  <i className="fa fa-pinterest" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default NodeDevelopemntContentWrapper;
