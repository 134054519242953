import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Career from "../components/Careers";

const PageAbout = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/about-us-header.jpg")}
        title="Careers"
        content=""
      />
      <Career />
      <Footer />
    </Fragment>
  );
};

export default PageAbout;
