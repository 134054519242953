import React from "react";

function LogoItem(props) {
  return (
    <div className="brand-logo-item">
      <img
        src={require("../../assets/img/" + props.logoSrc)}
        alt="fuebeus-Logo"
        width="100"
        height="53"
      />
    </div>
  );
}

export default LogoItem;
